import baustein from "baustein";
import template from "templates/modules/featured_promotions_wall.jinja";
import "web/script/components/promotion-card";

export default baustein.register("featured-promotions-wall", {
    template,

    getInitialRenderContext() {
        return {
            featured_promotions_wall: {
                content_cards: [],
            },
        };
    },
});
