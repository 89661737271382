import React from "react";
import HomepageDesignerRail from "web/react/components/__LEGACY__/homepage-designer-rail/homepage-designer-rail";
import BrandValue from "web/react/components/brand-value/brand-value";
import FeaturedBanners from "web/react/components/featured-banners/featured-banners";
import { ProductRail } from "web/react/components/product-rail/product-rail";
import ProductTypeIndex from "web/react/components/product-type-index/product-type-index";
import SaleLandingHeader from "web/react/components/sale-landing-header/sale-landing-header";
import SaleLandingPageCategoriesRail from "web/react/components/sale-landing-page-categories-rail/sale-landing-page-categories-rail";
import SaleLandingPageLink from "web/react/components/sale-landing-page-link/sale-landing-page-link";
import SaleLandingPagePromoBanner from "web/react/components/sale-landing-page-promo-banner/sale-landing-page-promo-banner";
import { gettext } from "web/script/modules/django-i18n";
import { languageIsEnglish } from "web/script/modules/language";
import { SaleLandingPageLayoutSerializer } from "web/types/serializers";
import styles from "./sale-landing-page-layout.module.css";

interface SaleLandingPageLayoutProps {
    layoutData: SaleLandingPageLayoutSerializer;
}

export function SaleLandingPageLayout({
    layoutData: {
        header_title: headerTitle,
        sale_promo_banner: promoBanner,
        sale_page_categories: categories,
        sale_page_designers: designers,
        product_type_index: productIndex,
        opposite_gender_link: oppositeGenderLink,
        sale_page_offers: offersOfTheWeek,
        sale_page_featured_banners: featuredBanners,
        brand_values: brandValues,
        new_arrivals: newArrivals,
    },
}: SaleLandingPageLayoutProps): JSX.Element {
    const isEng = languageIsEnglish();

    return (
        <div data-tesid="promo-banner" className={styles.main}>
            {!promoBanner && <SaleLandingHeader title={headerTitle} />}
            {promoBanner && <SaleLandingPagePromoBanner {...promoBanner} />}
            {productIndex && <ProductTypeIndex links={productIndex.links} />}
            {designers && (
                <HomepageDesignerRail
                    items={designers.links}
                    analyticsLabel={"sale_landing_page_designers_rail"}
                />
            )}
            {categories && <SaleLandingPageCategoriesRail items={categories.links} />}
            {oppositeGenderLink && (
                <div className={styles.oppositeGenderLinkWrapper}>
                    <SaleLandingPageLink item={oppositeGenderLink} type={"opposite-gender"} />
                </div>
            )}
            {newArrivals && !!newArrivals.products.length ? (
                <ProductRail
                    id="product-rail"
                    title={gettext("general.new_on_lyst")}
                    products={newArrivals.products}
                    label="sales-landing-page-new-arrivals"
                />
            ) : offersOfTheWeek && !!offersOfTheWeek.products.length ? (
                <ProductRail
                    id="product-rail"
                    title={offersOfTheWeek.title}
                    products={offersOfTheWeek.products}
                    label="sales-page-offers-of-the-week"
                />
            ) : null}
            {isEng && brandValues && <BrandValue className={styles.brandValues} {...brandValues} />}
            {featuredBanners && <FeaturedBanners {...featuredBanners} />}
        </div>
    );
}

export default SaleLandingPageLayout;
