import baustein from "baustein";
import cookie from "web/script/utils/cookie";

const componentName = "fomo50";
const subscribePanel = `.${componentName}__subscribe-panel`;
const subscribePanelHide = "fomo50__subscribe-panel--hide";
const subscribeOverlaySelector = `.${componentName}__subscribe-overlay`;
const subscribeOverlayShow = "fomo50__subscribe-overlay--show";
const FOMO50_COOKIE = "fomo50";

export default baustein.register("fomo50", {
    init() {
        if (!cookie(FOMO50_COOKIE)) {
            this._openPanel();
        }
        window.cook = cookie;
    },

    setupEvents(add) {
        add("click", ".fomo50__open-overlay-button", this._openOverlay);
        add("click", ".fomo50__close-overlay-button", this._closeOverlay);
        add("click", ".fomo50__close-panel-button", this._closePanel);
        add("submit", ".fomo50__subscribe-form", this._onFormSubmit);
    },

    _openOverlay() {
        this._showOverlay();
    },

    _closeOverlay() {
        this._hideOverlay();
    },

    _openPanel() {
        const panel = this.el.querySelector(subscribePanel);

        panel.classList.remove(subscribePanelHide);
    },

    _closePanel() {
        const panel = this.el.querySelector(subscribePanel);

        this._setCookie();
        panel.classList.add(subscribePanelHide);
    },

    _onFormSubmit(event) {
        event.preventDefault();

        const emailAddress = this.el.querySelector(".fomo50__subscribe-form-email").value;
        const url = `https://Lyst.us18.list-manage.com/subscribe/post-json?u=c6e7d4b4ed3b6231d4d1dfc0c&amp;id=ba15566088&b_c6e7d4b4ed3b6231d4d1dfc0c_ba15566088&EMAIL=${emailAddress}&c=?`;

        fetch(url, {
            method: "GET",
            mode: "no-cors",
            cache: "no-cache",
        });
        this._hideOverlay();
        this._closePanel();
    },

    _showOverlay() {
        const overlay = this.el.querySelector(subscribeOverlaySelector);

        overlay.classList.add(subscribeOverlayShow);
    },

    _hideOverlay() {
        const overlay = this.el.querySelector(subscribeOverlaySelector);

        overlay.classList.remove(subscribeOverlayShow);
    },

    _setCookie() {
        cookie(FOMO50_COOKIE, 1, { expires: 30 });
    },
});
