import baustein from "baustein";
import browser from "web/script/utils/browser";

const NAME = "affiliate-promotion-code-input";
const CTA_SUCCESS_STATE = ["btn--done", "glyph", "glyph-small", "glyph-tick-black"];
const CTA_SUCCESS_TEXT = "copied!";

const BROWSER_OK = browser.chrome || browser.firefox;

/**
 * Affiliate Promotion Code Input
 *
 * Copies the value of a target input field within the component (marked with a
 * 'promotion-code-input__field' class) to the user's clipboard. Currently
 * only enabled for Chrome or Firefox (through browser sniffing) to make sure
 * the `execCommand` API is supported and predictable.
 *
 */
export default baustein.register(`${NAME}`, {
    onInsert() {
        if (BROWSER_OK) {
            this.el.classList.remove(`${NAME}--no-support`);
        }
    },

    setupEvents(add) {
        add("click", this.onItemClick);
    },

    onItemClick(event) {
        event.preventDefault();

        let input = this.el.querySelector(`.${NAME}__field`);
        let cta = this.el.querySelector(`.${NAME}__cta`);

        if (this.el.classList.contains(`${NAME}--done`)) {
            return;
        }

        input.select();
        document.execCommand("copy");
        input.blur();

        CTA_SUCCESS_STATE.forEach((clss) => cta.classList.add(clss));
        cta.textContent = CTA_SUCCESS_TEXT;
        this.el.classList.add(`${NAME}--done`);
    },
});
