import React from "react";
import { AboutUsNewsSerializer } from "web/types/serializers";
import styles from "./about-us-news.module.css";

function AboutUsNews({
    news,
    title,
    more_lyst_news_title: moreLystNewsTitle,
    more_lyst_news_url: moreLystNewsUrl,
}: AboutUsNewsSerializer): React.ReactElement {
    return (
        <div className={styles.news}>
            <h2>{title}</h2>
            <div className={styles.newsItemContainer}>
                {news.map(({ tag, title, content, url, image_url }) => (
                    <div key={title} className={styles.newsItem}>
                        <a href={url}>
                            <div className={styles.newsItemImage}>
                                <img src={image_url} />
                            </div>
                            <div className={styles.newsItemTag}>
                                <span>{tag}</span>
                            </div>
                            <div className={styles.newsItemTitle}>{title}</div>
                            <div className={styles.newsItemContent}>{content}</div>
                        </a>
                    </div>
                ))}
            </div>
            <a href={moreLystNewsUrl}>
                <div className={styles.moreNewsButton}>{moreLystNewsTitle}</div>
            </a>
        </div>
    );
}

export default AboutUsNews;
