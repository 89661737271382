import clsx from "clsx";
import React from "react";
import { EditingToolsSerializer } from "web/types/serializers";
import styles from "./editing-tools.module.css";

interface EditingToolsProps {
    editingTools: EditingToolsSerializer;
    title?: string;
}

export function EditingTools({
    editingTools: {
        edit_url: editUrl,
        add_url: addUrl,
        delete_url: deleteUrl,
        current_region: currentRegion,
        user_region: userRegion,
    },
}: EditingToolsProps): JSX.Element {
    return (
        <>
            <a
                href={editUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={clsx(styles.button, "button")}
            >
                {`edit`} {currentRegion && `for ${currentRegion}`}
            </a>

            {addUrl && (
                <a
                    href={addUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={clsx(styles.button, "button")}
                >
                    {`create for ${userRegion}`}
                </a>
            )}

            {deleteUrl && (
                <a
                    href={deleteUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={clsx(styles.button, "button", "important-button")}
                >
                    {`delete ${userRegion} content`}
                </a>
            )}
        </>
    );
}

export default EditingTools;
