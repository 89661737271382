interface WatcherData {
    fn: (state: boolean) => void;
    last: boolean | null;
}

export const watchers = new Map<string, IntersectionObserver>();
export let watchedElements = new WeakMap<Element, WatcherData>();

function domToObj(domEl): Record<string, any> {
    let keepAttrs = ["id", "tagName", "className"];
    let obj = {};
    for (let attr of keepAttrs) {
        obj[attr] = domEl[attr];
    }
    return obj;
}

function replacer(key, value): any {
    if (key == "root" && value instanceof Element) {
        return domToObj(value);
    }
    return value;
}

function createIntersectionObserver(options): IntersectionObserver {
    return new IntersectionObserver((entries, observer) => {
        for (let entry of entries) {
            let el = entry.target;
            let data = watchedElements.get(el);
            if (!data) {
                observer.unobserve(el);
                continue;
            }
            let intersecting = entry.isIntersecting;
            if (intersecting !== data.last) {
                data.fn(intersecting);
                data.last = intersecting;
            }
        }
    }, options);
}

function getWatcher(fn: any, options: IntersectionObserverInit): IntersectionObserver {
    // A replacer function is requred for the case when the 'root' option is provided
    // The root is an HTML element which will cause a circular reference error when stringified
    let key = JSON.stringify(options, replacer);
    let watcher = watchers.get(key);

    if (watcher) {
        return watcher;
    }

    const intersectionObserver = createIntersectionObserver(options);

    watchers.set(key, intersectionObserver);

    return intersectionObserver;
}

export function addVisibilityWatcher(
    el: HTMLElement,
    fn: any,
    options: IntersectionObserverInit = { rootMargin: "0px 0px 250px 0px" }
): () => void {
    let watcher = getWatcher(fn, options);

    watchedElements.set(el, {
        fn: fn,
        last: null,
    });
    watcher.observe(el);

    return (): void => {
        watcher.unobserve(el);
        watchedElements.delete(el);
    };
}

export function clearWatchers(): void {
    watchers.clear();
    watchedElements = new WeakMap<Element, WatcherData>();
}
