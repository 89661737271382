import baustein from "baustein";
import _isArray from "lodash/isArray";
import _isString from "lodash/isString";
import analytics from "web/script/analytics/analytics";
import globals from "web/script/modules/globals";
import browser from "web/script/utils/browser";
import url from "web/script/utils/url";

baustein.register("redirect-to-retailer", {
    defaultOptions: {
        isReturning: false,
        retailerName: "",
        redirectUrl: "",
        productId: "",
        originUrl: "",
        soldOutLead: false,
        returnUrl: "",
        interstitialLink: false,
        salePrice: 0,
        isCssLead: false,
        retailerSlug: "",
        designerSlug: "",
        productCategory: "",
        productGender: "",
    },

    init: function () {
        var productId = this.options.productId;
        var category = this.options.isReturning ? "NAVIGATION" : "AFFILIATE";
        var getGAClientIDPromise = analytics.getGAClientID();
        var action = "LEAD";
        var urlParams = url.toURLSearchParams(globals.window.location.search);
        var onTrackingFinish = this._onTrackingFinish.bind(this);
        var promises;

        if (this.options.isReturning) {
            action = "RETAILER_BOUNCE";
        } else if (this.options.soldOutLead) {
            action = "LEAD_SOLD_OUT";
        }

        // Setup lead return URLs
        if (!this.options.isReturning && browser.supportsHistory) {
            this._updateHistoryState();
        }

        setTimeout(function () {
            analytics.event(action, "timeout", productId, true);
            onTrackingFinish(null);
        }, 10000);

        // Send all the analytics events we need
        promises = [
            getGAClientIDPromise,
            analytics.event(category, action, this.options.productId, false, {
                sale_price: this.options.salePrice,
                isCssLead: this.options.isCssLead,
                retailer_slug: this.options.retailerSlug,
                designer_slug: this.options.designerSlug,
                category: this.options.productCategory,
                gender: this.options.productGender,
                product_id: productId,
                atc_grouping: urlParams.get("atc_grouping"),
            }),
            analytics.whenQueueIsEmpty(),
        ];

        Promise.all(promises)
            .then(onTrackingFinish)
            .catch(() => {
                // explicitly pass null here as we won't want whatever error
                // gets passed to this handler to be confused with the client id
                onTrackingFinish(null);
            });
    },

    _onTrackingFinish: function (values) {
        var clientId = _isArray(values) ? values[0] : null;
        if (!_isString(clientId)) {
            clientId = null;
        }
        this._doRedirect(clientId);
    },

    _doRedirect: function (clientId) {
        if (!this.options.redirectUrl) {
            return;
        }

        // if user is coming back and we have a url for the product page send them there
        if (this.options.isReturning) {
            this._handleReturn();
            return;
        }

        this._redirect(clientId);
    },

    _handleReturn: function () {
        if (globals.window.opener) {
            // if going back to a feed instead of a PDP then add productId to query string and replace history
            globals.window.close();
        } else if (this.options.interstitialLink) {
            globals.window.close();
        } else {
            globals.window.location = this.options.originUrl;
        }
    },

    _redirect: async function (clientId) {
        // For Google CSS shopping we do not want the user to return back on lyst,
        // so this ensures it is not added to the browser history
        var redirectUrl = this._getClientIdUrl(clientId);
        var urlParams = url.toURLSearchParams(globals.window.location.search);
        var atcGrouping = urlParams.get("atc_grouping");

        if (atcGrouping && atcGrouping === "Google-PLA-CSS") {
            globals.window.location.replace(redirectUrl);
        } else {
            globals.window.location = redirectUrl;
        }
    },

    _getClientIdUrl: function (clientId) {
        var parsed = url.parse(this.options.redirectUrl);
        parsed.searchParams.set("client_id", clientId);
        return url.unparse(parsed);
    },

    /**
     * Function used to determine the behaviour of the back button from the retailer page.
     */
    _updateHistoryState: function () {
        var urlParams = url.toURLSearchParams(globals.window.location.search);
        if (["Google-PLA-CSS", "Bing-PLA", "css"].indexOf(urlParams.get("atc_grouping")) > -1) {
            // PAID-1170
            // Instead of pushing the return redirect url to the history stack, update the
            // most recent entry with the origin url.
            // This should remedy the confusing back button behaviour that has caused users
            // to get stuck on this page when hitting the back button.
            //
            // Also persist existing query param values to ensure that it is tracked
            // as CSS activity in analytics.
            var replaceUrl = this.options.originUrl + globals.window.location.search;
            window.history.replaceState(null, document.title, replaceUrl);
        } else {
            // 1. Do it twice, because... chrome bug (when going back it seems to
            //    skip over the first page)
            // 2. Need to use the actual history API here and not our internal wrapper
            //    module as that actually prevents the creation of duplicate history entries.
            window.history.pushState(null, document.title, this.options.returnUrl);
            window.history.pushState(null, document.title, this.options.returnUrl);
        }
    },
});
