import baustein from "baustein";
import traversal from "web/script/dom/traversal";

export default baustein.register("partners-signup-page", {
    setupEvents(add) {
        add("change", '[data-widget="drop-down-or-text"]', (event) => {
            this._showOrHideOptionalSibling(event.target);
        });
        add("change", 'select[name^="e_commerce_platform"]', (event) => {
            this._showOrHideAltAppRoute(event.target);
        });
    },

    onInsert() {
        this._initDisplayState();
    },

    _initDisplayState() {
        // hide / show optional siblings depending on current state
        const selectsElArray = this.el.querySelectorAll('[data-widget="drop-down-or-text"]');
        // making sure it's an array instead of NodeList. forEach of NodeList not widely supported
        Array.from(selectsElArray).forEach((el) => {
            this._showOrHideOptionalSibling(el);
        });
    },

    _showOrHideOptionalSibling(dropDownElement) {
        // find optional input field element
        const dropDownElementParent = traversal.closest(dropDownElement, ".form-item");
        const optionalSibling = dropDownElementParent.querySelector("input[type='text']");
        // show optional field if 'other' is selected. Hide it otherwise
        if (dropDownElement.value.toLowerCase() === "other") {
            optionalSibling.classList.remove("hidden");
            optionalSibling.required = true;
        } else {
            optionalSibling.classList.add("hidden");
            optionalSibling.value = "";
            optionalSibling.required = false;
        }
    },

    _showOrHideAltAppRoute(dropDownElement) {
        // to either show or hide card (and other form fields) for
        // alternative application route. i.e. through Shopify instead
        const dropDownElementParent = traversal.closest(dropDownElement, ".form-item");
        const selectedOption = dropDownElement.value.toLowerCase() || null;
        let elementToMatch = "*";

        if (selectedOption === "shopify") {
            elementToMatch = dropDownElementParent;
        }
        this._showMatchedAndHideOthers(".form-item, .form-button", elementToMatch);
        this._showMatchedAndHideOthers(
            "[data-show-alt]",
            this.el.querySelector(`[data-show-alt=${selectedOption}]`)
        );
    },

    _showMatchedAndHideOthers(selector, elementToMatch) {
        // show selected alternative content card and hide the rest
        // use asterisk (*) to match all
        Array.from(this.el.querySelectorAll(selector)).forEach((el) => {
            if (el === elementToMatch || elementToMatch === "*") {
                el.classList.remove("hidden");
            } else {
                el.classList.add("hidden");
            }
        });
    },
});
