
    const _each = require("lodash/each");
    const _times = require("lodash/times");
    const _escape = require("lodash/escape");
    const _throttle = require("lodash/throttle");

    const _ = {
        each: _each,
        times: _times,
        escape: _escape,
        throttle: _throttle,
    };

    module.exports = function(context) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="loading-square ';
 if (context && context.isWhite) { ;
__p += 'loading-square--white';
 } ;
__p += '"><div class="loading-square__lr"></div><div class="loading-square__tb"></div></div>';
return __p
};
    