import TrendIcon from "img/trends-hub/trend-icon.svg?inline";
import React from "react";
import CategoryLinks, { GenericType } from "web/react/components/category-links/category-links";
import { MustHaveTrendsSerializer } from "web/types/serializers";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./trends-landing-page-must-have-trends.module.css";

function TrendsLandingPageMustHaveTrends({
    title,
    trends,
}: MustHaveTrendsSerializer): JSX.Element | null {
    return trends?.length ? (
        <div className={styles.wrapper}>
            <div className={styles.titleBlock}>
                <TrendIcon />
                <h2 className={styles.title}>{title}</h2>
            </div>
            <CategoryLinks
                className={styles.categoryLinks}
                genericTypes={trends as GenericType[]}
                id="trends-page-hub-category-links"
            />
        </div>
    ) : null;
}

export default TrendsLandingPageMustHaveTrends;
