import React from "react";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { gettext } from "web/script/modules/django-i18n";
import styles from "./product-type-index.module.css";

interface ProductTypeLink {
    title: string;
    url: string;
}

interface ProductTypeIndexProps {
    links: ProductTypeLink[];
}

function ProductTypeIndex({ links }: ProductTypeIndexProps): JSX.Element {
    return (
        <ul className={styles.productTypeIndex}>
            {links.map((link) => (
                <li key={link.url} className={styles.item}>
                    <a className={styles.link} href={link.url}>
                        <h3 className={styles.title}>{link.title.toUpperCase()}</h3>
                        <span className={styles.link}>
                            {
                                gettext("homepage.module_shop_sales.link") // Shop the sale
                            }
                            <SVGIcon className={styles.chevron} name={"chevron"} />
                        </span>
                    </a>
                </li>
            ))}
        </ul>
    );
}

export default ProductTypeIndex;
