import baustein from "baustein";
import optionSelect, { optionInputSelector } from "./mixins/option-select";

const componentName = "product-type-options";
const nativeSelectSelector = "select[name='product_type']";

export default baustein.register(componentName, {
    mixins: [optionSelect],

    setupEvents(add) {
        add("click", optionInputSelector, this.emitProductTypeChangedEvent);
        add("change", nativeSelectSelector, this.emitProductTypeChangedEvent);
    },

    emitProductTypeChangedEvent(event) {
        this.emit("productTypeChanged", { selectedProductType: event.target.value || null });
    },

    updateProductTypeOptions(taxonomyMap, targetGender, targetProductType) {
        let newOptions = Object.values(taxonomyMap[targetGender]);

        let newLabel = "";
        newOptions.forEach((option) => {
            option["checked"] = option["value"] === targetProductType;
            if (option["checked"]) {
                newLabel = option["label"];
            }
        });

        let context = this.getRenderContext();
        context.option_select.current_options = newOptions;
        context.option_select.selected_option_label = newLabel;
        this.setRenderContext(context);
    },
});
