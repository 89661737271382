import baustein from "baustein";
import _each from "lodash/each";
import browser from "web/script/utils/browser";

var TAB_SELECTOR = ".gender-category-navigation__tab";
var TYPE_SECTION_SELECTOR = ".gender-category-navigation__section";
var ACTIVE_TAB_CLASS = "gender-category-navigation__tab--active";
var TYPE_DESCRIPTION_SELECTOR = ".gender-category-navigation__description";

export default baustein.register("gender-category-navigation", {
    getInitialRenderContext: function () {
        return {
            gender_category_navigation: {
                product_types: [],
                is_womens: true,
            },
        };
    },

    setupEvents: function (add) {
        add("click", TAB_SELECTOR, this._onTabClicked);
    },

    onInsert: function () {
        var tabContainer = this.el.querySelector(".gender-category-navigation__tabs");

        var descriptions = this.el.querySelectorAll(TYPE_DESCRIPTION_SELECTOR);
        _each(descriptions, (description) => {
            description.classList.remove("hidden");
        });

        if (tabContainer) {
            tabContainer.classList.remove("hidden");
        }

        if (browser.getViewport() > browser.VIEWPORTS.TABLET) {
            this.selectSection(0);

            var headings = this.el.querySelectorAll(".gender-category-navigation__section-header");
            _each(headings, function (heading) {
                heading.classList.add("hidden");
            });
        }
    },

    selectSection: function (index) {
        var sections = this.el.querySelectorAll(TYPE_SECTION_SELECTOR);
        var tabs = this.el.querySelectorAll(TAB_SELECTOR);

        if (index > sections.length - 1 || index < 0) {
            return;
        }

        this.hideAllSections(sections);
        this.showSection(sections[index]);
        this.deactivateAllTabs(tabs);
        this.activateTab(tabs[index]);
    },

    showSection: function (section) {
        section.classList.remove("hidden");
    },

    hideSection: function (section) {
        section.classList.add("hidden");
    },

    hideAllSections: function (sections) {
        _each(
            sections,
            function (section) {
                this.hideSection(section);
            }.bind(this)
        );
    },

    deactivateAllTabs: function (tabs) {
        _each(tabs, function (tab) {
            tab.classList.remove(ACTIVE_TAB_CLASS);
        });
    },

    activateTab: function (tab) {
        tab.classList.add(ACTIVE_TAB_CLASS);
    },

    _onTabClicked: function (event) {
        var index = event.target.getAttribute("index");

        this.selectSection(index);
    },
});
