import clsx from "clsx";
import React from "react";
import Rail from "web/react/components/LEGACY_rail/rail";
import EditingTools from "web/react/components/editing-tools/editing-tools";
import LazyLoadImage from "web/react/components/lazy-load-image/lazy-load-image";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { useClickTracker } from "web/react/hooks/use-click-tracker/use-click-tracker";
import { EditingToolsSerializer } from "web/types/serializers";
import styles from "./featured-banners.module.css";

interface FeaturedBannersItem {
    url: string;
    image_url: string;
    title: string;
    text: string;
    cta_text: string;
}

interface FeaturedBannersProps {
    banner_rows: FeaturedBannersItem[][];
    editing_tools?: EditingToolsSerializer | null;
}

export function FeaturedBannerItem({
    title,
    text,
    cta_text: ctaText,
    url,
    image_url: imgUrl,
}: FeaturedBannersItem): JSX.Element {
    const eventCategory = "featured banner homepage";
    const eventAction = "clicked";
    const textOnClick = useClickTracker(eventCategory, eventAction, `text: ${text}`);
    const imageOnClick = useClickTracker(eventCategory, eventAction, `image: ${text}`);

    return (
        <div className={styles.featuredItem}>
            <a href={url} onClick={imageOnClick} role="presentation">
                <LazyLoadImage className={styles.featuredImage} src={imgUrl} role="presentation" />
            </a>

            <a href={url} onClick={textOnClick} className={styles.details}>
                <span>
                    <h3 className={styles.title}>{title.toUpperCase()}</h3>
                    <p className={styles.teaser}>{text}</p>
                </span>
                <span>
                    <span className={styles.ctaLink}>
                        {ctaText} <SVGIcon className={styles.chevron} name="chevron" />
                    </span>
                </span>
            </a>
        </div>
    );
}

function FeaturedBanners({
    banner_rows: bannerRows,
    editing_tools: editingTools,
}: FeaturedBannersProps): JSX.Element {
    const leftButtonStyle = clsx(styles.scrollButton, styles.left);
    const rightButtonStyle = clsx(styles.scrollButton, styles.right);

    return (
        <div className={styles.featuredBannersWrap}>
            {editingTools && (
                <div className={styles.editingTools}>
                    <EditingTools editingTools={editingTools} />
                </div>
            )}
            <Rail
                title={""}
                analyticsLabel={"featured-banners"}
                leftButtonStyle={leftButtonStyle}
                rightButtonStyle={rightButtonStyle}
                buttonIcon={"square-chevron"}
                className={styles.featuredBanners}
            >
                {bannerRows.map((row) =>
                    row.map((item) => <FeaturedBannerItem key={item.url} {...item} />)
                )}
            </Rail>
        </div>
    );
}

export default FeaturedBanners;
