import baustein from "baustein";
import template from "templates/modules/filtered_promotions_wall.jinja";
import "web/script/components/promotion-card";

export default baustein.register("filtered-promotions-wall", {
    template,

    getInitialRenderContext() {
        return {
            filtered_promotions_wall: {
                promo_ids: [],
            },
        };
    },
});
