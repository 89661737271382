import React from "react";
import DragRail from "web/react/components/drag-rail/drag-rail";
import { MostPopularSerializer } from "web/types/serializers";
import styles from "./trends-landing-page-most-popular-rail.module.css";

function TrendsLandingPageMostPopularTrends({
    title,
    links,
}: MostPopularSerializer): JSX.Element | null {
    return links?.length >= 10 ? (
        <div className={styles.trendsMostWantedBase}>
            <div>
                <h4 className={styles.trendsMostWantedTitle}>{title}</h4>
            </div>
            <div className={styles.trendsMostWantedContent}>
                <DragRail
                    id={"trends-most-wanted-top-rail"}
                    className={styles.trendsMostWantedRail}
                >
                    <div className={styles.trendsMostWantedRailContent}>
                        {links.slice(0, 5).map((link) => {
                            return (
                                <a
                                    key={link.url}
                                    href={link.url}
                                    className={styles.trendsMostWantedItemText}
                                >
                                    {link.title}
                                </a>
                            );
                        })}
                    </div>
                </DragRail>
                <DragRail
                    id={"trends-most-wanted-bottom-rail"}
                    className={styles.trendsMostWantedRail}
                >
                    <div className={styles.trendsMostWantedRailContent}>
                        {links.slice(5).map((link) => {
                            return (
                                <a
                                    key={link.url}
                                    href={link.url}
                                    className={styles.trendsMostWantedItemText}
                                >
                                    {link.title}
                                </a>
                            );
                        })}
                    </div>
                </DragRail>
            </div>
        </div>
    ) : null;
}

export default TrendsLandingPageMostPopularTrends;
