export default {
    /**
     * Find the closest parent node that matches the selector, or itself. Use this to
     * ensure you have the correct element you want to query, or a specific parent.
     *
     * This exists due to cross platform reasons, when `element.closest` is supported
     * cross browser, that should be used.
     * @param {HTMLElement} element - the element to search from
     * @param {string} selector - the selector to find
     */
    closest: function (element, selector) {
        // IE11 compatability for events on SVG shadow DOMs
        // https://stackoverflow.com/a/33677641/823542
        if (element.correspondingUseElement) {
            element = element.correspondingUseElement;
        }
        return element.closest(selector);
    },

    /**
     * Polyfill for Element.prototype.matches
     *
     * @param {HTMLElement} element - the element to match against
     * @param {string} selector - the selectors to match
     */
    matches: function (element, selector) {
        return element.matches(selector);
    },

    /**
     * Returns true if `a` and `b` are equal or `b` is contained by `a`.
     * @param {HTMLElement} a
     * @param {HTMLElement} b
     * @returns {Boolean}
     */
    contains(a, b) {
        return a.contains(b);
    },
};
