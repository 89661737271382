import baustein from "baustein";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import { svgIcon } from "web/script/modules/jinja-filters";
import requester from "web/script/modules/requester";
import loadingCircle from "web/script/templates/loading-spinner.html";
import logging from "web/script/utils/logging";

const SUBSCRIPTION_SELECTOR = "account-settings__subscription__switch";
const SUBSCRIPTION_SELECTOR_STATUS = "account-settings__subscription__switch-status";
const SUBSCRIPTION_SELECTOR_CONTAINER_SHOW = "account-settings__subscription__switch-status--show";
const SUBSCRIPTION_SELECTOR_CONTAINER_SUCCESS =
    "account-settings__subscription__switch-status--success";
const SUBSCRIPTION_SELECTOR_CONTAINER_ERROR =
    "account-settings__subscription__switch-status--error";

const SETTINGS_CONTAINER_SELECTOR = "account-settings__container";
const SETTINGS_FIELD_SELECTOR_STATUS = "account-settings__container__field-status";
const SETTINGS_FIELD_SELECTOR_SHOW = "account-settings__container__field-status--show";
const SETTINGS_FIELD_SELECTOR_SUCCESS = "account-settings__container__field-status--success";
const SETTINGS_FIELD_SELECTOR_ERROR = "account-settings__container__field-status--error";

export default baustein.register("account-settings", {
    init() {
        this.states = {};
        this.country;
    },

    setupEvents(add) {
        add("focus", "select[name='country']", this.onCountryFocus);
        add("click", "select[name='country']", this.onCountryClick);
        add("change", "select[name='country']", this.onCountryChange);
        add("change", ".account-settings__gender-switch-input", this.onGenderChange);
        add("change", ".account-settings__subscription__switch-input", this.onSubscriptionChange);
    },

    onCountryFocus(event) {
        this.country = event.target.value;
    },

    onCountryClick() {
        if (!this.states["country"]) {
            analytics.event("Country", "Clicked", "Select field");
        }
    },

    onCountryChange(event) {
        const country = event.target.value;
        if (this.states["country"]) {
            return;
        }

        analytics.event("Country", "Changed", null);

        this.states["country"] = 1;
        const countryContainer = event.target.closest(`.${SETTINGS_CONTAINER_SELECTOR}`);
        const statusContainer = countryContainer.querySelector(
            `.${SETTINGS_FIELD_SELECTOR_STATUS}`
        );
        this.lockInput(countryContainer);
        statusContainer.classList.add(SETTINGS_FIELD_SELECTOR_SHOW);
        // "Updating your settings"
        this.showLoading(statusContainer, gettext("settings.account.updating"));

        this._request({ country })
            .then(() => {
                analytics.event("Country", "Changed", "Successfully", true);
                this.showSuccess(
                    statusContainer,
                    SETTINGS_FIELD_SELECTOR_SUCCESS,
                    // "Your location has been updated"
                    gettext("settings.account.country.update_successful")
                );
                setTimeout(() => {
                    this.cleanupSettings(statusContainer, countryContainer, "country");
                }, 2500);
            })
            .catch((e) => {
                analytics.event("Country", "Changed", "Error", true);
                logging.error("Could not update location", e);
                countryContainer.querySelector("select").value = this.country;
                this.showError(
                    statusContainer,
                    SETTINGS_FIELD_SELECTOR_ERROR,
                    // "Problem updating your location"
                    gettext("settings.account.country.update_failed")
                );
                setTimeout(() => {
                    this.cleanupSettings(statusContainer, countryContainer, "country");
                }, 2500);
            });
    },

    onGenderChange(event) {
        const gender = event.target.value;
        if (this.states["gender"]) {
            return;
        }

        analytics.event("Gender", "Changed", null);

        this.states["gender"] = 1;
        const genderContainer = event.target.closest(`.${SETTINGS_CONTAINER_SELECTOR}`);
        const statusContainer = genderContainer.querySelector(`.${SETTINGS_FIELD_SELECTOR_STATUS}`);
        this.lockInput(genderContainer);
        statusContainer.classList.add(SETTINGS_FIELD_SELECTOR_SHOW);
        // "Updating your settings"
        this.showLoading(statusContainer, gettext("settings.account.updating"));

        this._request({ gender })
            .then(() => {
                analytics.event("Gender", "Changed", "Successfully", true);
                this.showSuccess(
                    statusContainer,
                    SETTINGS_FIELD_SELECTOR_SUCCESS,
                    // "Your shopping preferences have been updated"
                    gettext("settings.account.gender.update_successful")
                );
                setTimeout(() => {
                    this.cleanupSettings(statusContainer, genderContainer, "gender");
                }, 2500);
            })
            .catch((e) => {
                analytics.event("Gender", "Changed", "Error", true);
                logging.error("Could not update gender", e);
                let previousEl = genderContainer.querySelector(`input:not([value="${gender}"])`);
                previousEl.checked = true;
                this.showError(
                    statusContainer,
                    SETTINGS_FIELD_SELECTOR_ERROR,
                    // "Problem updating your shopping preferences"
                    gettext("settings.account.gender.update_failed")
                );
                setTimeout(() => {
                    this.cleanupSettings(statusContainer, genderContainer, "gender");
                }, 2500);
            });
    },

    onSubscriptionChange(event) {
        const subscriptionType = event.target.name;
        const isSubscribed = event.target.value;
        const data = {};
        data[subscriptionType] = isSubscribed;

        if (this.states[subscriptionType]) {
            return;
        }

        analytics.event("Subscription Settings", `Changed - ${subscriptionType}`, null);

        const subscriptionContainer = event.target.closest(`.${SUBSCRIPTION_SELECTOR}`);
        const statusContainer = subscriptionContainer.querySelector(
            `.${SUBSCRIPTION_SELECTOR_STATUS}`
        );

        this.states[subscriptionType] = 1;
        this.lockInput(subscriptionContainer);
        // "Updating your settings"
        this.showLoading(statusContainer, gettext("settings.account.updating"));
        statusContainer.classList.add(SUBSCRIPTION_SELECTOR_CONTAINER_SHOW);

        this._request(data)
            .then(() => {
                analytics.event(
                    "Subscription Settings",
                    `Changed - ${subscriptionType}`,
                    "Successfully",
                    true
                );
                this.showSuccess(
                    statusContainer,
                    SUBSCRIPTION_SELECTOR_CONTAINER_SUCCESS,
                    // "Your settings have been updated"
                    gettext("settings.account.subscriptions.update_successful")
                );
                setTimeout(() => {
                    this.cleanupSubscriptions(
                        statusContainer,
                        subscriptionContainer,
                        subscriptionType
                    );
                }, 2500);
            })
            .catch((e) => {
                analytics.event(
                    "Subscription Settings",
                    `Changed - ${subscriptionType}`,
                    "Error",
                    true
                );
                logging.error(`Could not update ${subscriptionType} subscription settings`, e);
                let previousEl = subscriptionContainer.querySelector(
                    `input:not([value="${isSubscribed}"])`
                );
                previousEl.checked = true;
                this.showError(
                    statusContainer,
                    SUBSCRIPTION_SELECTOR_CONTAINER_ERROR,
                    // "Problem updating your settings"
                    gettext("settings.account.subscriptions.update_failed")
                );
                setTimeout(() => {
                    this.cleanupSubscriptions(
                        statusContainer,
                        subscriptionContainer,
                        subscriptionType
                    );
                }, 2500);
            });
    },

    cleanupSubscriptions(statusContainer, container, subscriptionType) {
        statusContainer.innerHTML = "";
        statusContainer.classList.remove(SUBSCRIPTION_SELECTOR_CONTAINER_SUCCESS);
        statusContainer.classList.remove(SUBSCRIPTION_SELECTOR_CONTAINER_ERROR);
        statusContainer.classList.remove(SUBSCRIPTION_SELECTOR_CONTAINER_SHOW);
        this.unlockInput(container);
        this.states[subscriptionType] = undefined;
    },

    cleanupSettings(statusContainer, container, stateField) {
        statusContainer.innerHTML = "";
        statusContainer.classList.remove(SETTINGS_FIELD_SELECTOR_SUCCESS);
        statusContainer.classList.remove(SETTINGS_FIELD_SELECTOR_ERROR);
        statusContainer.classList.remove(SETTINGS_FIELD_SELECTOR_SHOW);
        this.unlockInput(container);
        this.states[stateField] = undefined;
    },

    _request(data) {
        const options = {
            body: data,
            method: "POST",
        };
        return requester("/account/", options).then(requester.toJSON);
    },

    lockInput(container) {
        Array.from(container.querySelectorAll("input")).forEach((el) => (el.disabled = true));
        Array.from(container.querySelectorAll("select")).forEach((el) => (el.disabled = true));
    },

    unlockInput(container) {
        Array.from(container.querySelectorAll("input")).forEach((el) => (el.disabled = false));
        Array.from(container.querySelectorAll("select")).forEach((el) => (el.disabled = false));
    },

    showLoading(container, message) {
        let spinner = document.createElement("div");
        spinner.innerHTML = loadingCircle();
        spinner.classList.add("account-settings__loading");
        container.appendChild(spinner);
        container.append(message);
    },

    showSuccess(container, className, message) {
        container.classList.add(className);
        container.innerHTML = "";
        const icon = svgIcon("tick");
        container.innerHTML = icon;
        container.append(message);
    },

    showError(container, className, message) {
        container.classList.add(className);
        container.innerHTML = "";
        const icon = svgIcon("cross");
        container.innerHTML = icon;
        container.append(message);
    },
});
