import baustein from "baustein";
import _isString from "lodash/isString";
import analytics from "web/script/analytics/analytics";

export default baustein.register("tracked-form", {
    tagName: "form",

    defaultOptions: {
        eventCategory: null,
        eventAction: null,
        eventLabel: null,
    },

    setupEvents: function (add) {
        add("submit", this.onSubmit);
    },

    onSubmit: function (event) {
        var category = this.options.eventCategory;
        var action = this.options.eventAction;
        var label = this.options.eventLabel;

        if (!_isString(category) || !_isString(action)) {
            return;
        }

        event.preventDefault();

        this.emit("trackedFormSubmit");

        analytics.event(category, action, label).then(
            function () {
                this.el.submit();
            }.bind(this)
        );
    },
});
