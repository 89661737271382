import clsx from "clsx";
import React, { useState } from "react";
import EditingTools from "web/react/components/editing-tools/editing-tools";
import LazyLoadImage from "web/react/components/lazy-load-image/lazy-load-image";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import { EditingToolsSerializer } from "web/types/serializers";
import styles from "./trends-landing-page-main-banner.module.css";

interface TrendsLandingPageMainBannerProps {
    title: string;
    cta_text: string;
    url: string;
    image_url: string;
    mobile_image_url?: string | null;
    editing_tools?: EditingToolsSerializer | null;
}

export function InfoButton(): JSX.Element {
    const [isActive, setIsActive] = useState(false);

    function toggleInfo(): void {
        analytics.event("brand_ads", "click", "main_banner_icon", false);
        setIsActive(!isActive);
    }

    return (
        <button
            className={clsx(styles.info, {
                [styles.active]: isActive,
            })}
            onClick={toggleInfo}
        >
            <span className={clsx(styles.infoIcon)}>{"i"}</span>
            <span className={clsx(styles.infoLabel)}>{gettext("feed.header.sponsored_label")}</span>
        </button>
    );
}

export function TrendsLandingPageMainBanner({
    title,
    cta_text: ctaText,
    url,
    image_url: imageUrl,
    mobile_image_url: mobileImageUrl,
    editing_tools: editingTools,
}: TrendsLandingPageMainBannerProps): JSX.Element {
    const { isDesktopViewport } = useDomViewport();
    const image = isDesktopViewport ? imageUrl : mobileImageUrl || imageUrl;

    return (
        <div data-testid="trends-page-main-banner">
            <div className={styles.bannerWrapper}>
                <div className={styles.titleWrapper}>
                    <a href={url}>
                        <h3 className={styles.title}>{title}</h3>
                        <span className={styles.ctaLink}>
                            {ctaText} <SVGIcon className={styles.arrow} name="arrow-right" />
                        </span>
                    </a>
                </div>
                <div className={styles.imageWrapper}>
                    <a href={url} role={"presentation"}>
                        <LazyLoadImage className={styles.image} src={image} role={"presentation"} />
                    </a>
                    <InfoButton />
                </div>
            </div>
            {editingTools && (
                <div className={styles.editingTools}>
                    <EditingTools editingTools={editingTools} />
                </div>
            )}
        </div>
    );
}
