import baustein from "baustein";
import style from "web/script/dom/style";
import { addVisibilityWatcher } from "web/script/modules/visibility-watcher";

export default baustein.register("lazy-image", {
    defaultOptions: {
        imageSrc: null,
        imageSrcset: null,
        isBackgroundImage: false,
        scrollParentId: "",
    },

    init: function () {
        this.isLoaded = false;
        this._removeVisiblityWatcher = null;
    },

    onInsert: function () {
        if (!this.options.imageSrc && !this.options.imageSrcset) {
            return;
        }

        // Allow images this far outside of the browsers viewport vertically and horizontally to start loading.
        let visibilityOptions = { rootMargin: "800px" };

        if (this.options.scrollParentId != "") {
            visibilityOptions.root = document.getElementById(this.options.scrollParentId);
        }

        this._removeVisiblityWatcher = addVisibilityWatcher(
            this.el,
            (visible) => {
                if (visible) {
                    this.loadImage();
                    this._removeVisiblityWatcher();
                }
            },
            visibilityOptions
        );
    },

    onRemove() {
        if (this._removeVisiblityWatcher) {
            this._removeVisiblityWatcher();
        }
    },

    loadImage: function () {
        if (!this.options.imageSrc && !this.options.imageSrcset) {
            return;
        }

        this.isLoaded = true;

        if (this.options.isBackgroundImage) {
            style.setStyle(this.el, "background-image", `url(${this.options.imageSrc})`);
        } else {
            if (this.options.imageSrc) {
                this.el.src = this.options.imageSrc;
                this.el.style.visibility = "";
            }

            if (this.options.imageSrcset) {
                this.el.srcset = this.options.imageSrcset;
                this.el.style.visibility = "";
            }
        }
    },
});
