import LinkedInIcon from "img/about-us-page/linked-in.svg?inline";
import React from "react";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { AboutUsPressCoverageSerializer } from "web/types/serializers";
import styles from "./about-us-press-coverage.module.css";

function AboutUsPressCoverage({
    items,
    title,
    linked_in_title: linkedInTitle,
    linked_in_url: linkedInUrl,
}: AboutUsPressCoverageSerializer): React.ReactElement {
    return (
        <div className={styles.pressCoverage}>
            <div className={styles.pressCoverageContainer}>
                <h2 dangerouslySetInnerHTML={{ __html: title }} />
                <div className={styles.pressCoverageItemContainer}>
                    {items.map(({ title, content, url }) => (
                        <a
                            key={url}
                            className={styles.pressCoverageItem}
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                            title={content}
                        >
                            <div>
                                <div className={styles.pressCoverageItemHeading}>
                                    <h3 dangerouslySetInnerHTML={{ __html: title }} />
                                    <SVGIcon name={"arrow-right"} />
                                </div>
                                {content && (
                                    <div
                                        className={styles.pressCoverageItemTitle}
                                        dangerouslySetInnerHTML={{ __html: content }}
                                    />
                                )}
                            </div>
                        </a>
                    ))}
                </div>
                <a
                    href={linkedInUrl}
                    target="_blank"
                    rel="noreferrer noopener"
                    title={linkedInTitle}
                >
                    <div className={styles.followOnLinkedInButton}>
                        <LinkedInIcon className={styles.followOnLinkedInButtonIcon} />
                        <span dangerouslySetInnerHTML={{ __html: linkedInTitle }} />
                    </div>
                </a>
            </div>
        </div>
    );
}

export default AboutUsPressCoverage;
