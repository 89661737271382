import baustein from "baustein";
import analytics from "web/script/analytics/analytics";
import "web/script/components/product-card";
import traversal from "web/script/dom/traversal";
import globals from "web/script/modules/globals";
import { addVisibilityWatcher } from "web/script/modules/visibility-watcher";
import browser from "web/script/utils/browser";

export default baustein.register("promotion-card", {
    defaultOptions() {
        return {
            promoTitle: "",
            promoSource: "",
        };
    },

    setupEvents(add) {
        add("click", "a", this.onLinkClick);
    },

    onInsert() {
        this._removeVisiblityWatcher = addVisibilityWatcher(this.el, (visible) => {
            if (visible) {
                this.onView();
                this._removeVisiblityWatcher();
            }
        });
    },

    onRemove() {
        if (this._removeVisiblityWatcher) {
            this._removeVisiblityWatcher();
        }
    },

    onView() {
        const { promoTitle, promoSource } = this.options;
        analytics.event("promotion-card", `${promoSource}_show`, promoTitle, false);
    },

    onLinkClick(event) {
        const destinationUrl = traversal.closest(event.target, "a").href;
        const newTab =
            (browser.mac && event.metaKey) || (!browser.mac && event.ctrlKey) || event.which === 2;
        const isProductCardClick = !!traversal.closest(event.target, ".product-card");
        const { promoTitle, promoSource } = this.options;
        let action = `${promoSource}_click`;
        if (isProductCardClick) {
            action += "_productcard";
        }

        if (event.target.target === "_blank" || newTab) {
            analytics.event("promotion-card", action, promoTitle, false);
        } else {
            event.preventDefault();
            analytics.event("promotion-card", action, promoTitle, false).then(() => {
                globals.window.location = destinationUrl;
            });
        }
    },
});
