import baustein from "baustein";

export default baustein.register("show-extra", {
    setupEvents: function (add) {
        add("click", ".show-more-button", this.revealHiddenContent);
    },

    revealHiddenContent: function (event) {
        event.stopPropagation();
        event.preventDefault();
        // remove show more section
        var showMore = this.el.querySelector(".show-more");
        this.el.removeChild(showMore);

        // show the hidden description
        var hiddenDescription = this.el.querySelector("[data-show-extra]");
        hiddenDescription.classList.remove("hidden");
    },
});
