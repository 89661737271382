import clsx from "clsx";
import React from "react";
import Rail from "web/react/components/LEGACY_rail/rail";
import RelatedSearchProductImages from "web/react/components/related-searches-rail/related-search-product-images";
import { LinkWithImageSerializer } from "web/types/serializers";
import styles from "./trends-landing-page-new-releases-rail.module.css";

interface NewReleasesRailProps {
    title?: string;
    links: LinkWithImageSerializer[];
}

function NewReleasesRail({ title, links }: NewReleasesRailProps): JSX.Element {
    const leftBtnStyle = clsx(styles.scrollButton, styles.left);
    const rightBtnStyle = clsx(styles.scrollButton, styles.right);

    return (
        <div className={styles.railWrapper}>
            <h3 className={styles.titleBlock}>{title}</h3>
            <Rail
                analyticsLabel={"new_releases"}
                className={styles.railWrapper}
                leftButtonStyle={leftBtnStyle}
                rightButtonStyle={rightBtnStyle}
                buttonIcon={"arrow-right"}
            >
                {links.map((link) => (
                    <div key={link.url} className={styles.cardWrapper}>
                        <div className={styles.cardDimension}>
                            <a href={link.url}>
                                <RelatedSearchProductImages link={link} />
                            </a>
                            <a href={link.url} className={styles.cardTitle}>
                                {link.title}
                            </a>
                        </div>
                    </div>
                ))}
            </Rail>
        </div>
    );
}

export default NewReleasesRail;
