import baustein from "baustein";
import analytics from "web/script/analytics/analytics";
import productClickMixin from "./mixins/product-click-mixin";

export default baustein.register("cart-item", {
    mixins: [productClickMixin],

    defaultOptions: {
        overlayEnabled: true,
        reason: "cart-item",
    },

    setupEvents(add) {
        add("trackedFormSubmit", this._onRemoveCartItem);
    },

    _onRemoveCartItem(event) {
        event.preventDefault();
        const productData = this.options.productAnalyticsData;

        if (!productData) {
            return;
        }

        analytics.ecommerce("ec:addProduct", {
            name: productData.product_canonical_slug,
            category: productData.category,
            brand: productData.designer_slug,
            variant: productData.product_uid,
            price: productData.price,
            quantity: 1,
            dimension20: productData.gender,
            metric8: productData.sale_discount,
        });
        analytics.ecommerce("ec:setAction", "remove");
        analytics.event("CART", "REMOVE_ITEM", "cart_product_card");
    },

    getClickOverlayClasses() {
        return [".cart-item__image", ".cart-item__name"];
    },
});
