import React from "react";
import PromoSection from "web/react/components/promo-section/promo-section";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { AboutUsContentsSerializer } from "web/types/serializers";
import styles from "./about-us-contents.module.css";

function AboutUsContents({
    app_banners: appBanners,
    bottom_paragraphs: bottomParagraphs,
    links,
    stats,
    title,
    top_paragraphs: topParagraphs,
}: AboutUsContentsSerializer): React.ReactElement {
    return (
        <>
            <div className={styles.contents}>
                <h1 dangerouslySetInnerHTML={{ __html: title }} />
                <div className={styles.contentsTop}>
                    {topParagraphs.map((p) => (
                        <p key={p} dangerouslySetInnerHTML={{ __html: p }} />
                    ))}
                </div>
            </div>
            <div className={styles.statsSection}>
                <div className={styles.statsDividerDesktop}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div className={styles.statsDividerMobile}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div className={styles.stats}>
                    {stats.map(({ content, title, footnote }) => (
                        <div key={title}>
                            <h2 dangerouslySetInnerHTML={{ __html: title }} />
                            <p dangerouslySetInnerHTML={{ __html: content }} />
                            {footnote && (
                                <p
                                    className={styles.statsFootnote}
                                    dangerouslySetInnerHTML={{ __html: footnote }}
                                />
                            )}
                        </div>
                    ))}
                </div>
            </div>
            {appBanners && appBanners.length && (
                <div className={styles.promoSectionWrapper}>
                    <PromoSection data={appBanners} />
                </div>
            )}
            <div className={styles.contents}>
                {bottomParagraphs.map((p) => (
                    <p key={p} dangerouslySetInnerHTML={{ __html: p }} />
                ))}
            </div>
            <div className={styles.contentLinks}>
                {links.map(({ title, url }) => (
                    <div key={title}>
                        <a href={url} className={styles.contentLinkTitle} title={title}>
                            <span dangerouslySetInnerHTML={{ __html: title }} />
                            <SVGIcon className={styles.contentLinkIcon} name="arrow-right" />
                        </a>
                    </div>
                ))}
            </div>
        </>
    );
}

export default AboutUsContents;
