import clsx from "clsx";
import React from "react";
import Rail from "web/react/components/LEGACY_rail/rail";
import LazyLoadImage from "web/react/components/lazy-load-image/lazy-load-image";
import styles from "./homepage-designer-rail.module.css";

interface Item {
    title: string;
    image_url: string;
    image_alt: string;
    url: string;
}

interface DesignerRailItemProps {
    item: Item;
    type?: string;
}

const DESIGNER_CATEGORY = "DESIGNER_CATEGORY";

export function HomepageDesignerItem({ item, type }: DesignerRailItemProps): JSX.Element {
    const isDesignerCategory = type === DESIGNER_CATEGORY;
    return (
        <a
            data-testid="designer-rail-item"
            className={clsx(styles.homepageDesignerItem, {
                [styles.homepageDesignerCategoryItem]: isDesignerCategory,
            })}
            href={item.url}
        >
            <LazyLoadImage
                className={clsx(styles.homepageDesignerImg, {
                    [styles.homepageDesignerCategoryImg]: isDesignerCategory,
                })}
                src={item.image_url}
                alt={item.image_alt}
            />
            <span
                className={clsx(styles.homepageDesignerLink, {
                    [styles.homepageDesignerCategoryLink]: isDesignerCategory,
                })}
            >
                {item.title.toUpperCase()}
            </span>
        </a>
    );
}

interface HomepageDesignerRailProps {
    items: Item[];
    type?: string;
    analyticsLabel?: string;
}

export function HomepageDesignerRail({
    items,
    type,
    analyticsLabel = "homepage_designer_rail",
}: HomepageDesignerRailProps): JSX.Element {
    const buttonStyle = clsx(styles.scrollButton, {
        [styles.scrollCategory]: type === DESIGNER_CATEGORY,
    });
    const leftButtonStyle = clsx(buttonStyle, styles.left);
    const rightButtonStyle = clsx(buttonStyle, styles.right);

    return (
        <Rail
            className={styles.homepageDesignerRail}
            analyticsLabel={analyticsLabel}
            title={""}
            leftButtonStyle={leftButtonStyle}
            rightButtonStyle={rightButtonStyle}
            buttonIcon={"square-chevron"}
        >
            {items.map((item) => {
                return <HomepageDesignerItem key={item.url} item={item} type={type} />;
            })}
        </Rail>
    );
}

export default HomepageDesignerRail;
