/* eslint-disable max-depth */
import PropTypes from "prop-types";
import React, { useState } from "react";
import Button from "web/react/components/__LEGACY__/LEGACY_button/button";
import CloseButton from "web/react/components/buttons/close-button/close-button";
import PrimaryButton from "web/react/components/buttons/primary-button/primary-button";
import LazyModal from "web/react/components/lazy-modal/lazy-modal";
import { gettext } from "web/script/modules/django-i18n";
import url from "web/script/utils/url";
// eslint-disable-next-line css-modules/no-unused-class
import defaultStyles from "./unsubscribe-overlay.module.css";
// eslint-disable-next-line css-modules/no-unused-class

export function UnsubscribeOverlay({
    fullPriceCurrency,
    salePriceCurrency,
    contentRetailer,
    productImage,
    isOnSale,
    contentSizes,
    isOpen,
}) {
    const styles = defaultStyles;

    const [isVisible, setIsVisible] = useState(isOpen);

    let price = <span className={styles.price}>{fullPriceCurrency}</span>;

    if (isOnSale) {
        price = (
            <React.Fragment>
                <span className={styles.priceOld}>{fullPriceCurrency}</span>
                <span className={styles.priceSale}>{salePriceCurrency}</span>
            </React.Fragment>
        );
    }

    function unsubscribe() {
        const parsedURL = url.parse(window.location);
        parsedURL.searchParams.set("do_unsubscribe", "1");
        const params = url.toQueryString(parsedURL.searchParams);
        const redirectTo = `${parsedURL.path}${params && `?${params}`}`;
        window.location = redirectTo;
        return;
    }

    return (
        <div id="unsubscribe-overlay">
            <LazyModal
                appElement={document.body}
                isOpen={isVisible}
                className={styles.modal}
                overlayClassName={styles.overlay}
                noScroll={false}
            >
                <div className={styles.modalContent}>
                    <CloseButton
                        className={styles.closeButton}
                        onClick={() => setIsVisible(false)}
                    />
                    <div className={styles.captureForm}>
                        <h3 className={styles.header}>
                            {gettext("notifications.unsusbcribe_alerts_overlay.title")}
                        </h3>
                        <div className={styles.product}>
                            <div className={styles.image}>
                                <img src={productImage} />
                            </div>
                            <div className={styles.productContent}>
                                {price}
                                <div className={styles.retailer}>{contentRetailer}</div>
                                <div className={styles.sizes}>{contentSizes}</div>
                            </div>
                        </div>
                        <div className={styles.buttons}>
                            <Button styleType={"secondary"} onClick={() => setIsVisible(false)}>
                                {gettext("notifications.option_no")}
                            </Button>
                            <PrimaryButton className={styles.buttonYes} onClick={unsubscribe}>
                                {gettext("notifications.option_yes")}
                            </PrimaryButton>
                        </div>
                    </div>
                </div>
            </LazyModal>
        </div>
    );
}

export default UnsubscribeOverlay;

UnsubscribeOverlay.propTypes = {
    fullPriceCurrency: PropTypes.string.isRequired,
    salePriceCurrency: PropTypes.string,
    contentRetailer: PropTypes.string.isRequired,
    productId: PropTypes.string.isRequired,
    productImage: PropTypes.string.isRequired,
    isOnSale: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool.isRequired,
    contentSizes: PropTypes.string.isRequired,
};
