import template from "templates/modules/option_select.jinja";

const baseClassName = "option-select";

const selectedOptionLabelSelector = `.${baseClassName}__selected-option-label`;
const triangleSelector = `.${baseClassName}__triangle`;
const nativeOptionSelector = "option";
const optionsContainerSelector = `.${baseClassName}__options-container`;
export const disabledContainerClassName = "disabled";
export const optionInputSelector = `.${baseClassName}__input`;
export const selectedContainerSelector = `.${baseClassName}__selected-container`;

export default {
    template,

    setupEvents(add) {
        add("click", selectedOptionLabelSelector, this.openDropDown);
        add("click", optionInputSelector, this.updateSelectedOption);
    },

    onInsert() {
        this._overrideNativeSelectAutoSuggest();
    },

    /**
     *  To reset autosuggest for native select option when back button is clicked
     */
    _overrideNativeSelectAutoSuggest() {
        const selectedOption = this.options.currentOptions.find((option) => {
            return option["checked"] === true;
        }).label;

        this.el.querySelector(`${nativeOptionSelector}[label="${selectedOption}"]`).selected = true;
    },

    openDropDown(event) {
        // do not open dropdown if it's disabled
        if (event.target.parentElement.classList.contains(disabledContainerClassName)) {
            return;
        }

        import(/* webpackChunkName: "popper" */ "popper.js").then(({ default: popper }) => {
            const target = this.el.querySelector(selectedContainerSelector);
            const toPop = this.el.querySelector(optionsContainerSelector);
            new popper(target, toPop, {
                placement: "auto",
                eventsEnabled: false,
            });
            this.emit("openedDropDown");
            const elementsToShow = Array.from(
                this.el.querySelectorAll(`${triangleSelector},${optionsContainerSelector}`)
            );
            elementsToShow.forEach((element) => {
                element.classList.toggle("hidden");
            });
        });
    },

    closeDropDown() {
        const elementsToHide = Array.from(
            this.el.querySelectorAll(`${triangleSelector},${optionsContainerSelector}`)
        );
        elementsToHide.forEach((element) => {
            element.classList.add("hidden");
        });
    },

    updateSelectedOption(event) {
        this.el.querySelector(selectedOptionLabelSelector).textContent =
            event.target.nextElementSibling.textContent;
        this.closeDropDown();
    },

    getInitialRenderContext() {
        return {
            option_select: {
                component_name: this.options.componentName,
                title: this.options.title,
                selected_option_label: this.options.selectedOptionLabel,
                options_title: this.options.optionsTitle,
                column_count: this.options.columnCount,
                current_options: this.options.currentOptions,
            },
            underlined: this.options.underlined,
            fixed_height: this.options.fixedHeight,
        };
    },
};
