/** @module components/auth/social-auth-button */
import baustein from "baustein";
import { AUTH_TYPES } from "web/redux/ducks/customer-capture-overlay";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import globals from "web/script/modules/globals";
import { SocialLogin, SSO_PROVIDERS } from "web/script/utils/social-auth-login";
import url from "web/script/utils/url";

/**
 * @constructor
 * @alias module:components/auth/social-auth-button
 * @extends module:baustein
 */
export default baustein.register(
    "social-auth-button",
    /** @lends module:components/auth/social-auth-button.prototype */
    {
        defaultOptions: {
            authProvider: null,
            isSignup: true,
            authType: "normal",
            marketingEmails: false,
            nextUrl: "",
            reason: "",
            productId: null,
            designerId: null,
            searchQuery: null,
            searchGender: null,
            analyticsCategory: null,
            analyticsAction: null,
            analyticsLabel: null,
        },

        init() {
            switch (this.options.authProvider) {
                case "apple":
                    this.authProvider = SSO_PROVIDERS.apple;
                    break;
                case "facebook":
                    this.authProvider = SSO_PROVIDERS.facebook;
                    break;
                case "google":
                    this.authProvider = SSO_PROVIDERS.google;
                    break;
                default:
                    throw new Error(`${this.options.authProvider} is not a valid authProvider`);
            }

            if (!AUTH_TYPES.includes(this.options.authType)) {
                throw new Error(`${this.options.authType} is not a valid authType`);
            }
        },

        setupEvents(add) {
            add("click", this._startAuth);
        },

        async _startAuth(event) {
            event.preventDefault();
            analytics.event(
                this.options.analyticsCategory,
                this.options.analyticsAction,
                this.options.analyticsLabel
            );

            if (this.options.isSignup && !this._checkLegal()) {
                return;
            }

            // start with the current URL (including all query parameters)
            var parsedURL = url.parse(globals.window.location);

            // work out what the 'next' parameter should be
            var next = this.options.nextUrl || url.unparse(parsedURL);
            let reason = this.options.reason || "";

            // update the path to point to the correct auth provider
            parsedURL.searchParams.set("next", next);

            if (this.options.isSignup) {
                parsedURL.searchParams.set("marketingEmails", this.options.marketingEmails);
                parsedURL.searchParams.set("reason", reason);
                parsedURL.searchParams.set("isLogin", false);
            } else {
                // if auth type is "login" the backend will raise en exception and won't allow
                // user to login if they don't already have an account
                parsedURL.searchParams.set("isLogin", true);
            }

            parsedURL.searchParams.set("productId", this.options.productId);
            parsedURL.searchParams.set("designerId", this.options.designerId);
            parsedURL.searchParams.set("authType", this.options.authType);
            parsedURL.searchParams.set("searchQuery", this.options.searchQuery);
            parsedURL.searchParams.set("searchGender", this.options.searchGender);

            try {
                // do the redirect
                const redirectURL = await SocialLogin(parsedURL, this.authProvider);
                globals.window.location = redirectURL;
            } catch (e) {
                history.back();
            }
        },

        _checkLegal() {
            if (this.options.marketingEmails !== true) {
                // 'Almost there! Please confirm your subscription by ticking both boxes above.'
                const data = {
                    privacy_policy: [gettext("account.register.user_must_tick_both_boxes")],
                };
                this.emit("signup-legal-required", data);
                return false;
            } else {
                return true;
            }
        },
    }
);
