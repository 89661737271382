import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import LazyImage from "web/react/components/lazy-load-image/lazy-load-image";
import styles from "./related-search-product-images.module.css";

function RelatedSearchProductImages({ link }) {
    return (
        <div className={styles.relatedSearchImages}>
            {link.image_url ? (
                <LazyImage
                    key={link.image_url}
                    src={link.image_url}
                    alt={link.image_alt}
                    className={clsx(styles.relatedSearchImage)}
                    width={128}
                    height={160}
                />
            ) : (
                <span className={styles.fallback}>{"L"}</span>
            )}
        </div>
    );
}

RelatedSearchProductImages.propTypes = {
    link: PropTypes.shape({
        image_url: PropTypes.string,
        image_alt: PropTypes.string,
    }),
};

export default RelatedSearchProductImages;
