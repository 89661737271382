import React from "react";
import styles from "./sale-landing-header.module.css";

interface SaleLandingHeaderProps {
    title: string;
}

export function SaleLandingHeader({ title }: SaleLandingHeaderProps): JSX.Element {
    return (
        <div className={styles.headerWrap}>
            <h1 className={styles.title}>{title}</h1>
        </div>
    );
}

export default SaleLandingHeader;
