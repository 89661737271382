import React from "react";
import { AppFooter } from "web/react/components/app-footer/app-footer";
import BrandValue from "web/react/components/brand-value/brand-value";
import FeaturedBanners from "web/react/components/featured-banners/featured-banners";
import { FooterProps } from "web/react/components/footer/footer";
import TrendsLandingHeader from "web/react/components/trends-landing-header/trends-landing-header";
import BestSellingRail from "web/react/components/trends-landing-page-best-selling-rail/trends-landing-page-best-selling-rail";
import TrendsFooter from "web/react/components/trends-landing-page-footer/trends-landing-page-footer";
import { TrendsLandingPageMainBanner } from "web/react/components/trends-landing-page-main-banner/trends-landing-page-main-banner";
import MostPopularTrends from "web/react/components/trends-landing-page-most-popular-rail/trends-landing-page-most-popular-rail";
import MustHaveTrends from "web/react/components/trends-landing-page-must-have-trends/trends-landing-page-must-have-trends";
import NewArrivalsRail from "web/react/components/trends-landing-page-new-arrivals-rail/trends-landing-page-new-arrivals-rail";
import NewReleasesRail from "web/react/components/trends-landing-page-new-releases-rail/trends-landing-page-new-releases-rail";
import { TrendsLandingPageLayoutSerializer } from "web/types/serializers";
import styles from "./trends-landing-page-layout.module.css";

interface TrendsLandingPageLayoutProps {
    layoutData: TrendsLandingPageLayoutSerializer;
    footerData: FooterProps;
}

export function TrendsLandingPageLayout({
    layoutData: {
        page_title: pageTitle,
        opposite_gender_link: oppositeGenderLink,
        main_featured_banner: mainHeaderBanner,
        trends_new_releases: newReleases,
        trends_best_selling: bestSelling,
        brand_values: brandValues,
        must_have_trends: mustHaveTrends,
        trends_most_popular: trendsMostPopular,
        footer_trends: footerTrends,
        ad_units: adUnits,
        new_arrivals: newArrivals,
    },
    footerData,
}: TrendsLandingPageLayoutProps): JSX.Element {
    return (
        <>
            <main className={styles.main}>
                {mainHeaderBanner &&
                    mainHeaderBanner.banner_rows.length === 1 &&
                    mainHeaderBanner.banner_rows[0] && (
                        <TrendsLandingPageMainBanner
                            {...mainHeaderBanner.banner_rows[0][0]} // First row and first column
                            editing_tools={mainHeaderBanner.edit_tools}
                        />
                    )}
                {pageTitle && oppositeGenderLink && (
                    <TrendsLandingHeader
                        pageTitle={pageTitle}
                        oppositeGenderLink={oppositeGenderLink}
                    />
                )}
                {newReleases && !!newReleases?.links?.length && (
                    <NewReleasesRail {...newReleases} />
                )}
                {adUnits && (
                    <FeaturedBanners
                        banner_rows={adUnits.banner_rows}
                        editing_tools={adUnits.edit_tools}
                    />
                )}
                {bestSelling && !!bestSelling?.links?.length && (
                    <BestSellingRail {...bestSelling} />
                )}
                {newArrivals && !!newArrivals?.products?.length && (
                    <NewArrivalsRail {...newArrivals} />
                )}
                {mustHaveTrends && <MustHaveTrends {...mustHaveTrends} />}
                {brandValues && <BrandValue className={styles.brandValue} {...brandValues} />}
                {trendsMostPopular && <MostPopularTrends {...trendsMostPopular} />}
            </main>
            {footerTrends && !!footerTrends?.links?.length ? (
                <TrendsFooter {...footerData} footerTrends={footerTrends} />
            ) : (
                <AppFooter {...footerData} />
            )}
        </>
    );
}
