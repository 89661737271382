import clsx from "clsx";
import React, { RefObject, useRef } from "react";
import Slider from "react-slick";
import ProductCard from "web/react/components/product-card/react/product-card";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import analytics from "web/script/analytics/analytics";
import { ProductsCarouselSerializer } from "web/types/serializers";
import styles from "./products-carousel.module.css";

type ArrowProps = {
    className?: string; // react-slick passed
    sliderRef: RefObject<Slider>;
};

export function PrevArrow({ className, sliderRef }: ArrowProps): JSX.Element {
    function handlePrevClick(): void {
        analytics.event("carousel", "previous");
        if (sliderRef.current) {
            // TODO: its a terrible to idea to reference inner state like this - we should use the props & callbacks in this instance
            const current = (sliderRef.current as any).innerSlider.state.currentSlide;
            sliderRef.current.slickGoTo(current - 2);
        }
    }

    return (
        <>
            {
                <button
                    onClick={handlePrevClick}
                    className={clsx(styles.scrollButton, styles.scrollButtonPrev, className)}
                >
                    <SVGIcon name="chevron" flip />
                </button>
            }
        </>
    );
}

export function NextArrow({ className, sliderRef }: ArrowProps): JSX.Element {
    function handleNextClick(): void {
        analytics.event("carousel", "next");
        if (sliderRef.current) {
            // TODO: its a terrible to idea to reference inner state like this - we should use the props & callbacks in this instance
            const current = (sliderRef.current as any).innerSlider.state.currentSlide;
            const slideCount = (sliderRef.current as any).innerSlider.state.slideCount;
            const slidesToShow = (sliderRef.current as any).innerSlider.props.slidesToShow;
            if (slideCount - 1 - current === slidesToShow) {
                sliderRef.current.slickGoTo(current + 1);
            } else {
                sliderRef.current.slickGoTo(current + 2);
            }
        }
    }

    return (
        <>
            {
                <button
                    onClick={handleNextClick}
                    className={clsx(styles.scrollButton, styles.scrollButtonNext, className)}
                >
                    <SVGIcon name="chevron" />
                </button>
            }
        </>
    );
}

function ProductsCarousel({
    title,
    products: products,
}: ProductsCarouselSerializer): JSX.Element | null {
    const sliderRef = useRef<Slider>(null);
    if (products?.length === 0) {
        return null;
    }

    const slickSettings = {
        arrows: true,
        draggable: false,
        infinite: false,
        slidesToShow: 5,
        slidesToScroll: 2,
        swipe: false,
        touchMove: false,
        prevArrow: <PrevArrow sliderRef={sliderRef} />,
        nextArrow: <NextArrow sliderRef={sliderRef} />,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2,
                    swipe: true,
                    touchMove: true,
                    draggable: true,
                    arrows: false,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    swipe: true,
                    touchMove: true,
                    draggable: true,
                    arrows: false,
                },
            },
        ],
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.titleWrapper}>
                    <h5 className={styles.title} data-content={title}>
                        {title}
                    </h5>
                </div>
            </div>
            <div className={styles.carouselWrapper}>
                <Slider ref={sliderRef} className={styles.products} {...slickSettings}>
                    {products?.map((product) => (
                        <div key={product.uid} className={styles.productCard}>
                            <ProductCard product={product}>
                                <ProductCard.Image width={125} height={156} />

                                <ProductCard.SaveForLaterButton />

                                <ProductCard.Details />
                            </ProductCard>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
}

export default ProductsCarousel;
