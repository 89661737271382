/** @module components/accordion-container */
import baustein from "baustein";

/**
 * Accordion containers component that expands and collapses child accordions.
 *
 * # Accordion Behaviours
 * There are different accordion behaviours (set via 'data-accordion-toggle' attribute):
 *      'normal' (or blank) - Accordion collapses when another accordion expands or when it's been selected
 *      'sticky' - Accordion expands on load and stays expanded even if another accordion expands.
 *                 When selected it collapses and starts behaving like a normal accordion.
 *      'expanded' -  Accordion is expanded on load and behaves like a normal accordion thereafter.
 *
 *  # Accordion Groups
 *  Setting the attribute 'data-accordion-group' on an accordion sets this accordion to only expand and collapse
 *  if it or another accordion with the same group name is selected. Not setting a group on an accordion is allowed,
 *  it will be added to an implicit group of non-grouped accordions.
 *
 *  # Accordion Options
 *  Optional attributes you can add to the accordion element.
 *      'data-accordion-no-animation' - Adding this to the accordion will toggle 'display: block' and 'display: none'
 *                                      on the accordion content, instead of using 'max-height' with animations.
 *
 * @example
 * <div is="accordion-container"
 *      accordion-name="filters"
 *      default-accordion="0">
 *
 *      <a data-accordion-toggle data-accordion-group='shippingreturns' href="#shipping-toggle1">Shipping</a>
 *      <div id="#shipping-toggle1">
 *          <p>Shipping content and information here</p>
 *      </div>
 *
 *      <a data-accordion-toggle='sticky' data-accordion-group='shippingreturns' href="#returns-toggle1">Returns</a>
 *      <div id="#returns-toggle1">
 *          <p>Returns content and information here</p>
 *      </div>
 *
 *      <a data-accordion-toggle='expanded' data-accordion-no-animation href="#sizes-toggle1">Sizes</a>
 *      <div id="#sizes-toggle1">
 *          <p>Sizes information here</p>
 *      </div>
 *
 * </div>
 *
 * @constructor
 * @alias module:components/accordion-container
 * @extends module:baustein
 */
export default baustein.register(
    "accordion-container",
    /** @lends module:components/accordion-container.prototype */
    {
        defaultOptions: {
            defaultAccordion: 0,
            accordionName: null,
            expandedIds: null,
        },

        onInsert() {
            const defaultAccordionIndex = parseInt(this.options.defaultAccordion, 10) || 0;

            this._getAccordions().forEach((accordion, index) => {
                const contentEl = this._getAccordionContent(accordion);

                if (!contentEl) {
                    return;
                }

                // Set data attribute on content for styling
                contentEl.setAttribute("data-accordion-content", "");

                // if already expanded, ignore
                if (contentEl.getAttribute("data-accordion-expanded") === "true") {
                    return;
                }

                const isSelected = index === defaultAccordionIndex;
                this._handleAccordionSelected(accordion, index, isSelected, true);
            });
        },

        /**
         * Expands all the accordions matching the IDs provided.
         * @param accordionNames
         */
        expandAccordions(accordionNames) {
            if (!accordionNames) {
                return;
            }

            this._getAccordions().forEach((accordion, index) => {
                const contentEl = this._getAccordionContent(accordion);
                let contentId;
                if (contentEl) {
                    // Set data attribute on content for styling
                    contentEl.setAttribute("data-accordion-content", "");
                    contentId = contentEl.getAttribute("id");
                } else {
                    return;
                }

                if (!accordionNames.includes(contentId)) {
                    return;
                }
                this._handleAccordionSelected(accordion, index, true, true);
            });
        },

        setupEvents(add) {
            add("click", "a[data-accordion-toggle]", this._onClick);
        },

        _onClick(event, link) {
            event.preventDefault();
            this.setCurrentAccordion(link);
        },

        /**
         * Set the current expanded accordion
         *
         * @param newAccordion - The new accordion to expand
         * @private
         */
        setCurrentAccordion(newAccordion) {
            let groupName;
            if (newAccordion && newAccordion.hasAttribute("data-accordion-group")) {
                groupName = newAccordion.getAttribute("data-accordion-group");
            }
            this._getAccordionsByGroup(groupName).forEach((accordion, index) => {
                const isSelected = newAccordion === accordion;
                this._handleAccordionSelected(accordion, index, isSelected);
            });
        },

        /**
         * Returns an array of ID's of the expanded accordions.
         * @returns {Array<String>} of the ID's of the accordion content that are visible
         */
        getExpandedAccordions() {
            const elements = Array.from(
                this.el.querySelectorAll('[data-accordion-content][data-accordion-expanded="true"]')
            );
            return elements.map((el) => el.getAttribute("id"));
        },

        _handleAccordionSelected(accordion, index, isSelected, initial = false) {
            const contentEl = this._getAccordionContent(accordion);
            const isSticky = accordion.getAttribute("data-accordion-toggle") === "sticky";
            const isExpanded = accordion.getAttribute("data-accordion-toggle") === "expanded";

            if (contentEl) {
                let expandContent = true;
                const isStickyNotSelected = isSticky && !isSelected;

                if (!isStickyNotSelected && !isExpanded) {
                    expandContent = isSelected && this._isCollapsed(accordion, contentEl);
                }

                // Set expanded accordion to normal after initial setup
                if (isExpanded && initial) {
                    accordion.setAttribute("data-accordion-toggle", "normal");
                }

                // Set expanded accordion to normal when accordion is selected
                if (isSticky && isSelected && !initial) {
                    accordion.setAttribute("data-accordion-toggle", "normal");
                }

                this._expandContent(expandContent, accordion, contentEl, index);
            }
        },

        /**
         * Expands and collapses accordion content and emits events
         *
         * @param {boolean} expandContent - True if the accordion content should expand
         * @param {Element} accordion - Accordion element
         * @param {Element} contentEl - Accordion content element
         * @param {number} index - Index of the accordion
         * @private
         */
        _expandContent(expandContent, accordion, contentEl, index) {
            const isAccordionAnimated = this._isAccordionAnimated(accordion);

            if (expandContent) {
                this.emit("accordion-expanded", {
                    index: index,
                    accordionName: this.options.accordionName,
                });

                accordion.setAttribute("aria-expanded", "true");
                contentEl.setAttribute("data-accordion-expanded", "true");

                if (!isAccordionAnimated) {
                    contentEl.style.display = "block";
                } else if (contentEl.scrollHeight > 0) {
                    contentEl.style.maxHeight = `${contentEl.scrollHeight}px`;
                } else {
                    contentEl.removeAttribute("style");
                }
            } else {
                this.emit("accordion-collapsed", {
                    index: index,
                    accordionName: this.options.accordionName,
                });

                accordion.setAttribute("aria-expanded", "false");
                contentEl.removeAttribute("data-accordion-expanded");

                if (isAccordionAnimated) {
                    contentEl.style.maxHeight = 0;
                } else {
                    contentEl.style.display = "none";
                }
            }
        },

        /**
         * Returns all the accordion elements by their group
         *
         * @param groupName - Accordion group name
         * @returns {Array} Array of accordion elements
         * @private
         */
        _getAccordionsByGroup(groupName) {
            let query = "[data-accordion-toggle]";
            if (groupName) {
                query = query.concat(`[data-accordion-group=${groupName}]`);
            } else {
                query = query.concat(":not([data-accordion-group])");
            }
            return Array.from(this.el.querySelectorAll(query));
        },

        /**
         * Returns all the accordion elements
         *
         * @returns {Array} Array of accordion elements
         * @private
         */
        _getAccordions() {
            let query = "[data-accordion-toggle]";
            return Array.from(this.el.querySelectorAll(query));
        },

        /**
         * Returns the respective content element for an accordion element
         *
         * @param accordion - Accordion element
         * @returns {Element} Accordion content element
         * @private
         */
        _getAccordionContent(accordion) {
            const contentId = accordion.getAttribute("href");
            return this.el.querySelector(contentId);
        },

        /**
         * Returns true if the accordion or its content are collapsed
         *
         * @param accordion - Accordion element
         * @param contentEl - Accordion content element
         * @returns {boolean} True if collapsed
         * @private
         */
        _isCollapsed(accordion, contentEl) {
            const isContentCollapsed = this._isContentCollapsed(contentEl);
            const hasAriaExpanded = accordion.getAttribute("aria-expanded");

            return isContentCollapsed || !hasAriaExpanded;
        },

        /**
         * Returns true if the content element has a zero height or maxHeight
         *
         * @param contentEl - Accordion content element
         * @returns {boolean} True if the content element has a zero height
         * @private
         */
        _isContentCollapsed(contentEl) {
            const hasZeroHeight =
                contentEl.scrollHeight === 0 &&
                !contentEl.style.maxHeight &&
                contentEl.style.display !== "block";
            const hasZeroMaxHeight =
                contentEl.style.maxHeight === "0px" || contentEl.style.maxHeight === "0";
            return Boolean(hasZeroHeight || hasZeroMaxHeight);
        },

        /**
         * Returns true if the accordion should be animated
         *
         * @param accordion - Accordion element
         * @returns {boolean} True if accordion is animated
         * @private
         */
        _isAccordionAnimated(accordion) {
            return !accordion.hasAttribute("data-accordion-no-animation");
        },

        /**
         * Collapses a list or all accordions if no accordions specified.
         *
         * @param accordions - List of accordions
         */
        collapseAccordions(accordions = this._getAccordions()) {
            accordions.forEach((accordion, index) => {
                const contentEl = this._getAccordionContent(accordion);
                if (contentEl) {
                    accordion.setAttribute("data-accordion-toggle", "normal");
                    this._expandContent(false, accordion, contentEl, index);
                }
            });
        },
    }
);
