import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import Button from "web/react/components/__LEGACY__/LEGACY_button/button";
import { gettext } from "web/script/modules/django-i18n";
import styles from "./promo-card.module.css";

function createMarkup(value) {
    return { __html: value };
}

export function PromoCard({
    isActive,
    codeHref,
    leadUrl,
    retailerLogo,
    discountText,
    description,
    lastUsed,
    expires,
    expireSoon,
    retailerName,
}) {
    let retailerImage = (
        <div className={styles.headerImage}>
            <img className={styles.logo} src={retailerLogo} alt={retailerName} />
        </div>
    );

    let retailerNameBox = (
        <div className={styles.headerImage}>
            <span>{retailerName}</span>
        </div>
    );

    let expireSoonBanner = (
        <span className={styles.expireSoonBanner}>
            {gettext("promotion_message.designer.soon_to_expire_label ")}
        </span>
    );

    const hasEndDate = expires === null ? false : true;

    const expiredDateText = (
        <div className={styles.infoBoxContent}>
            <span>
                <strong>
                    {expireSoon
                        ? gettext("promotion_message.designer.expires_in_label ")
                        : gettext("promotion_message.designer.expires_label")}
                </strong>
            </span>
            <span className={expireSoon ? styles.expireSoonText : styles.grayText}>{expires}</span>
        </div>
    );

    const infoBox = (
        <div className={styles.infoBox}>
            <div className={styles.infoBoxContent}>
                <span>
                    <strong>{gettext("promotion_message.designer.last_used_label ")}</strong>
                </span>
                <span className={styles.grayText}>{lastUsed}</span>
            </div>
            {hasEndDate && expiredDateText}
        </div>
    );

    const expiredDate = (
        <div className={styles.expiredDate}>
            {gettext("promotion_message.designer.expired_label")} {expires}
        </div>
    );

    function onClick(event) {
        event.preventDefault();
        if (isActive && codeHref) {
            window.open(codeHref, "_blank");
        }
        window.location = leadUrl;
    }

    return (
        <div className={styles.promoCard}>
            {expireSoon && expireSoonBanner}
            <div
                className={clsx(
                    styles.header,
                    isActive ? styles.headerActive : styles.headerInactive,
                    retailerLogo ? styles.headerSimilarPromo : null
                )}
            >
                {retailerLogo && retailerImage}
                {!retailerLogo && retailerNameBox}
                <div className={styles.headerContent}>
                    <div
                        className={styles.discount}
                        dangerouslySetInnerHTML={createMarkup(discountText)}
                    ></div>
                </div>
            </div>
            <div className={styles.content}>
                {!isActive && hasEndDate && expiredDate}
                <div className={styles.description}>{description}</div>
                <div>
                    <a href={leadUrl} onClick={onClick}>
                        <Button
                            className={isActive ? null : styles.buttonInactive}
                            fullWidth
                            element="span"
                            styleType={"cta"}
                        >
                            {gettext("promotion_message.designer.show_code_btn ")}
                        </Button>
                    </a>
                </div>
                {isActive && infoBox}
            </div>
        </div>
    );
}

export default PromoCard;

export const PromoCardPropType = PropTypes.shape({
    isActive: PropTypes.bool,
    codeHref: PropTypes.string,
    leadUrl: PropTypes.string,
    retailerLogo: PropTypes.string,
    discountText: PropTypes.string,
    description: PropTypes.string,
    lastUsed: PropTypes.string,
    expires: PropTypes.string,
    expireSoon: PropTypes.bool,
    retailerName: PropTypes.string,
});

PromoCard.propTypes = {
    isActive: PropTypes.bool,
    codeHref: PropTypes.string,
    leadUrl: PropTypes.string,
    retailerLogo: PropTypes.string,
    discountText: PropTypes.string,
    description: PropTypes.string,
    lastUsed: PropTypes.string,
    expires: PropTypes.string,
    expireSoon: PropTypes.bool,
    retailerName: PropTypes.string,
};
