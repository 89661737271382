import baustein from "baustein";
import analytics from "web/script/analytics/analytics";

export default baustein.register("notification", {
    async onInsert() {
        if (this.options.type && this.options.productSlug) {
            await analytics.event("saw", this.options.type, this.options.productSlug);
        }
    },
});
