import React from "react";
import Footer, { FooterProps, useFooterContext } from "web/react/components/footer/footer";
import { FooterTrendsSerializer } from "web/types/serializers";
import styles from "./trends-landing-page-footer.module.css";

const FOOTER_TRENDS_BLOCK = "FOOTER_TRENDS";

interface TrendsFooterBlock {
    footerTrends: FooterTrendsSerializer;
}

interface TrendsLandingPageFooterProps extends FooterProps, TrendsFooterBlock {}

function TrendsFooterBlock({ footerTrends }): React.ReactElement {
    const { openBlockId, setOpenBlock } = useFooterContext();

    return (
        <Footer.Block
            className={styles.trendsBlock}
            title={footerTrends.title}
            isOpen={openBlockId === FOOTER_TRENDS_BLOCK}
            onClick={(): void => setOpenBlock(FOOTER_TRENDS_BLOCK)}
        >
            <ul className={styles.links}>
                {footerTrends.links.map((link) => (
                    <li key={link.url}>
                        <a className={styles.link} href={link.url}>
                            {link.title}
                        </a>
                    </li>
                ))}
            </ul>
        </Footer.Block>
    );
}

export function TrendsLandingPageFooter({
    footerTrends,
    ...footerData
}: TrendsLandingPageFooterProps): React.ReactElement {
    return (
        <Footer {...footerData}>
            <Footer.LeadContent />

            <TrendsFooterBlock footerTrends={footerTrends} />

            <Footer.HelpInfo />
        </Footer>
    );
}

export default TrendsLandingPageFooter;
