/** @module components/signup-layout */
import baustein from "baustein";
/**
 * This no-op component is similar to the default-layout
 * Used so that the back button on signup pages results in a page reload if going back to
 * non overlay type page
 * @constructor
 * @alias module:components/signup-layout
 * @extends module:baustein
 */
export default baustein.register("signup-layout", {
    // DON'T ADD ANYTHING HERE
});
