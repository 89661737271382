import baustein from "baustein";
import browser from "web/script/utils/browser";

export const TOOLTIP_SHOW_STATE = "tooltip--show";

export default baustein.register("tooltip", {
    defaultOptions: {
        hintBubble: true,
        autoShow: null,
        autoHide: null,
    },

    /**
     * Based on whether the device has touch or not
     * bind the correct show event.
     * @param add
     */
    setupEvents(add) {
        if (browser.hasTouch) {
            if (this.options.hintBubble) {
                add("click", this.onClick);
            } else {
                // Stops hover state being triggered when you tap on elements
                this.el.classList.add("tooltip--no-hover");
            }
        }
    },

    onRemove() {
        if (this.timeoutShow) {
            clearTimeout(this.timeoutShow);
        }
        if (this.timeoutHide) {
            clearTimeout(this.timeoutHide);
        }
    },

    onInsert() {
        this.render();

        if (this.options.autoShow) {
            this.timeoutShow = setTimeout(() => {
                this._showTooltip();

                if (this.options.autoHide) {
                    this.timeoutHide = setTimeout(() => {
                        this._hideTooltip();
                    }, this.options.autoHide * 1000);
                }
            }, this.options.autoShow * 1000);
        }
    },

    render() {
        if (this.options.hintBubble) {
            const hintBubble = document.createElement("span");
            hintBubble.classList.add("hint-bubble");
            this.el.appendChild(hintBubble);
            this.el.classList.add("tooltip--hint-bubble");
        }
    },

    _showTooltip() {
        this.el.classList.add(TOOLTIP_SHOW_STATE);
        this.setGlobalHandler("touchstart", this._hideTooltip);
    },

    _hideTooltip() {
        this.el.classList.remove(TOOLTIP_SHOW_STATE);
        this.releaseAllGlobalHandlers();
    },

    /**
     * Event handler for touch enabled devices.
     */
    onClick() {
        this.el.classList.toggle(TOOLTIP_SHOW_STATE);

        if (this.el.classList.contains(TOOLTIP_SHOW_STATE)) {
            this.setGlobalHandler("touchstart", this._hideTooltip);
        }
    },
});
