import { clsx } from "clsx";
import React from "react";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import { gettext } from "web/script/modules/django-i18n";
import styles from "./ccpa-option.module.css";

function CcpaOption(): JSX.Element | null {
    const { isDomLoaded } = useDomViewport();
    if (isDomLoaded) {
        return (
            <div className={styles.optOutWidgetContainer}>
                {/* You can opt-out or opt-in from the selling of your personal information according to the terms of CCPA legislation by clicking the link below: */}
                <p>{gettext("privacy_policy.california_privacy_rights.one_trust_link")}</p>
                <a
                    id="ot-sdk-btn"
                    // styling applied directly in OneTrust platform
                    className={clsx(styles.link, "ot-sdk-show-settings")}
                >
                    {gettext("privacy_policy.california_privacy_rights.do_not_sell_or_share.label")}
                    {/* Do not sell or share my personal information */}
                </a>
            </div>
        );
    } else {
        return null;
    }
}

export default CcpaOption;
