/** @module components/cart-layout */
import baustein from "baustein";
import analytics from "web/script/analytics/analytics";
import "web/script/components/accordion-container";
import "web/script/components/cart-item";
import "web/script/components/tooltip";
import "web/script/components/tracked-form";
import globals from "web/script/modules/globals";

const CHECKOUT_BUTTON_SELECTOR = ".cart-breakdown__checkout-button";
const OOS_CART_ITEM_SELECTOR = ".cart-item__out-of-stock";
const CART_ITEM_COMPONENT = "cart-item";

/**
 * @constructor
 * @alias module:components/cart-layout
 * @extends module:baustein
 */
export default baustein.register("cart-layout", {
    init() {
        const numCartItems = this.findComponents(CART_ITEM_COMPONENT).length;
        // Track how many items (this includes any OOS items) are in the cart on view
        analytics.event("cart", "view", numCartItems, true);

        // Track the number of OOS items
        const numOOSItems = this.el.querySelectorAll(OOS_CART_ITEM_SELECTOR).length;
        if (numOOSItems > 0) {
            analytics.event("cart", "view_with_oos_item", numOOSItems, true);
        }
    },

    getInitialRenderContext() {
        return {
            cart_layout: {
                cart_orders: {
                    out_of_stock: {},
                },
                cart_breakdown: {},
            },
        };
    },

    setupEvents(add) {
        if (!this.options.openProductOverlays) {
            return;
        }

        add("click", CHECKOUT_BUTTON_SELECTOR, this._onClickCheckoutButton);
    },

    _onClickCheckoutButton(event) {
        event.preventDefault();

        let href = event.target.href;

        this.findComponents("cart-item").forEach((cartItem) => {
            const productData = cartItem.options.productAnalyticsData;

            if (productData) {
                analytics.ecommerce("ec:addProduct", {
                    name: productData.product_canonical_slug,
                    category: productData.category,
                    brand: productData.designer_slug,
                    variant: productData.product_uid,
                    price: productData.price,
                    quantity: 1,
                    dimension20: productData.gender,
                    metric8: productData.sale_discount,
                });
            }
        });

        analytics.ecommerce("ec:setAction", "checkout", {
            step: 1,
        });

        analytics.event("NAVIGATION", "CHECKOUT", "cart_checkout_button").then(() => {
            globals.window.location = href;
        });
    },
});
