/**
 * ProductClickMixin
 *
 * This mixin helps handle clicks on products; allowing the sending of analytics, opening new tabs
 * redirects or overlays to product pages.
 *
 * The simplest way to get started is to include the mixin in your baustein component and implement
 * the function 'getClickOverlayClasses' which should return the classes which link to your product.
 * You also need to include 'overlayEnabled', 'productSlug' and 'reason' options if you want overlays.
 *
 * ```
 *   // ...
 *   mixins: [productClickMixin],
 *
 *   defaultOptions: {
 *      overlayEnabled: false,
 *      productSlug: null,
 *      reason: null
 *   },
 *
 *   getClickOverlayClasses: function () {
 *      return ['.product-color__link'];
 *   }
 *
 * ```
 *
 * If you want specific classes to redirect to product pages you can implement the function
 * 'getClickRedirectClasses' then any clicks to those classes will not open an overlay but will
 * redirect to the product page.
 *
 * ```
 *   getClickRedirectClasses: function () {
 *      return ['.product-color__link2'];
 *   }
 *
 * ```
 *
 * If you want to send analytics for every product click you can do so by implementing the function
 * 'sendAnalytics'.
 *
 * ```
 *  sendAnalytics: function () {
 *      analytics.event('navigation', 'color-picker');
 *  }
 *
 * ```
 *
 */
import _isFunction from "lodash/isFunction";
import analytics from "web/script/analytics/analytics";
import globals from "web/script/modules/globals";
import { isDesktop, isMobile } from "web/script/modules/utils";
import browser from "web/script/utils/browser";
import history from "web/script/utils/history";
import url from "web/script/utils/url";

/**
 * Product click event handler that will either open an overlay, new tab or redirect to product page.
 */
function _onClick(event, link) {
    if (event.target.classList.contains("show-more-button")) {
        return;
    }

    event.preventDefault();
    this.sendAnalytics();

    let overlayEnabledOption = this.options.overlayEnabled;

    let overlayEnabled =
        overlayEnabledOption &&
        this.options.productSlug &&
        browser.supportsHistory &&
        !event.metaKey &&
        // Feature flag to use overlay on mobile too
        !browser.ios &&
        !browser.android &&
        isDesktop();

    this.emit("selected");

    if (!link) {
        return;
    }

    // if the overlay isn't enabled, and the target is to open a new tab, then bail out here
    if (!overlayEnabled && link.getAttribute("target") === "_blank") {
        // the lead-link click handler also calls `preventDefault` and calls window.open. we don't
        // want to to it twice.
        if (link.getAttribute("is") !== "lead-link") {
            globals.window.open(link);
        }
        return;
    }

    let newTab = _isNewTab(event);

    if (!overlayEnabled || newTab || !this.options.reason || isMobile()) {
        _redirectWithReason.call(this, event, link, newTab);
    } else {
        const href = link.getAttribute("product-href") || link.href;

        let queryParams = {
            reason: this.options.reason,
            link_id: this.options.linkId,
        };

        let url = new URL(href, window.location.href);
        for (let [k, v] of Object.entries(queryParams)) {
            url.searchParams.set(k, v);
        }

        history.pushState(url);

        this.emit("openOverlay", {
            url: href,
            queryParams,
        });
    }
}

/**
 * Product click event handler that will either redirect or open a new tab to product page.
 */
function _onClickRedirect(event, link) {
    if (event.target.classList.contains("show-more-button")) {
        return;
    }

    let newTab = _isNewTab(event);

    this.emit("selected");
    _redirectWithReason.call(this, event, link, newTab);
}

/**
 * Returns true if a new tab should be used for the event
 */
function _isNewTab(event) {
    return (browser.mac && event.metaKey) || (!browser.mac && event.ctrlKey) || event.which === 2;
}

function _redirectWithReason(event, link, newTab) {
    let openNewTab = newTab || false;
    let parsed = url.parse(link.href);

    event.preventDefault();

    if (this.options.reason) {
        parsed.searchParams.set("reason", this.options.reason);
    }

    if (openNewTab) {
        globals.window.open(url.unparse(parsed), "_blank");
        return;
    }

    globals.window.location = url.unparse(parsed);
}

export default {
    defaultOptions: {
        overlayEnabled: false,
        productUid: null,
        productSlug: null,
        reason: null,
        linkId: null,
    },

    setupEvents(add) {
        if (this.getClickOverlayClasses) {
            this.getClickOverlayClasses().forEach((classObj) =>
                add("click", classObj, _onClick.bind(this))
            );
        }
        if (this.getClickRedirectClasses) {
            this.getClickRedirectClasses().forEach((classObj) =>
                add("click", classObj, _onClickRedirect.bind(this))
            );
        }
    },

    init() {
        let clickMethodName = "getClickOverlayClasses";
        let clickRedirectMethodName = "getClickRedirectClasses";
        if (!_isFunction(this[clickMethodName]) && !_isFunction(this[clickRedirectMethodName])) {
            throw new Error(
                `Missing functions - at least '${clickMethodName}' or '${clickRedirectMethodName}' should be implemented`
            );
        }
    },

    /**
     * Override function in component to send custom analytics on every product click.
     */
    sendAnalytics() {
        // send your analytics
        analytics.event("file_usage", "file_usage", "product-click-mixin.js");
    },
};
