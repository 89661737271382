import React, { useState } from "react";
import analytics from "web/script/analytics/analytics";
// eslint-disable-next-line css-modules/no-unused-class
import defaultStyles from "./unsubscribe-feedback.module.css";
// eslint-disable-next-line css-modules/no-unused-class

export function UnsubscribeFeedback(): JSX.Element {
    const styles = defaultStyles;
    const [hasSentFeedback, setFeedbackSent] = useState(false);
    const [reason, setReason] = useState("");
    const [reasonError, setReasonError] = useState("");
    const [reasonOther, setReasonOther] = useState("");
    const [reasonOtherError, setReasonOtherError] = useState("");
    const [suggestions, setSuggestions] = useState("");

    function onSubmit(): void {
        let hasReasonError = false;
        let hasReasonOtherError = false;

        if (!reason.length) {
            hasReasonError = true;
            setReasonError("You must select a reason why you unsubscribed.");
        } else {
            setReasonError("");
        }

        if (reason === "other" && !reasonOther.length) {
            hasReasonOtherError = true;
            setReasonOtherError("Please write a reason.");
        } else {
            setReasonOtherError("");
        }

        if (hasReasonError || hasReasonOtherError) {
            return;
        }

        const payload = {
            _type: "survey_responses",
            _subtype: "unsubscribe-feedback",
            _version: "0.0.1",
            survey_id: "crm-unsubscribe-survey",
            response_json: JSON.stringify({
                reason: reason,
                reason_other: reasonOther,
                suggestions: suggestions,
            }),
        };
        analytics.trackWithMetaData("survey_response", payload);
        setFeedbackSent(true);
    }

    if (hasSentFeedback) {
        return (
            <div className="unsubscribe-feedback">
                <p className={styles.unsubscribeFeedbackSuccess}>
                    {"Thank you! We appreciate your feedback."}
                </p>
            </div>
        );
    }

    return (
        <div className={styles.unsubscribeFeedback}>
            <h2 className={styles.unsubscribeQuestion}>
                {"Would you help us out improving our emails by telling why you've unsubscribed?"}
            </h2>

            <div className={styles.unsubscribeFormGroup}>
                <input
                    type="radio"
                    name="reason"
                    value="too_many"
                    id="too_many"
                    onChange={(e): void => setReason(e.target.value)}
                />
                <label className="control-label" htmlFor="too_many">
                    {"I receive too many emails from Lyst"}
                </label>
            </div>

            <div className={styles.unsubscribeFormGroup}>
                <input
                    type="radio"
                    name="reason"
                    value="no_relevancy"
                    id="no_relevancy"
                    onChange={(e): void => setReason(e.target.value)}
                />
                <label className="control-label" htmlFor="no_relevancy">
                    {"I don't think the content is relevant"}
                </label>
            </div>

            <div className={styles.unsubscribeFormGroup}>
                <input
                    type="radio"
                    name="reason"
                    value="no_subscription"
                    id="no_subscription"
                    onChange={(e): void => setReason(e.target.value)}
                />
                <label className="control-label" htmlFor="no_subscription">
                    {"I don't remember subscribing to emails from Lyst"}
                </label>
            </div>

            <div className={styles.unsubscribeFormGroup}>
                <input
                    type="radio"
                    name="reason"
                    value="other"
                    id="other"
                    onChange={(e): void => setReason(e.target.value)}
                    checked={"other" === reason}
                />
                <label className="control-label" htmlFor="other">
                    {"Other: "}
                </label>
                <div className={styles.unsubscribeFeedbackReasonOther}>
                    <input
                        type="text"
                        placeholder="Write your reason..."
                        name="reason_other"
                        onClick={(): void => setReason("other")}
                        onChange={(e): void => setReasonOther(e.target.value)}
                    />
                    {reasonOtherError && <div className="form-item-error">{reasonOtherError}</div>}
                </div>
            </div>

            {reasonError && <div className="form-item-error">{reasonError}</div>}

            <h2 className={styles.unsubscribeQuestion}>
                {"What changes do we need to make to improve our emails?"}
            </h2>

            <div className={styles.unsubscribeFormGroup}>
                <textarea
                    name="suggestions"
                    rows={5}
                    onChange={(e): void => setSuggestions(e.target.value)}
                />
            </div>

            <div className={styles.unsubscribeFormGroup}>
                <button className="btn btn-primary btn btn-large pull-right" onClick={onSubmit}>
                    {"Submit"}
                </button>
            </div>
        </div>
    );
}

export default UnsubscribeFeedback;
