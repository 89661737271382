import clsx from "clsx";
import React from "react";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import styles from "./sale-landing-page-link.module.css";

interface Item {
    title: string;
    url: string;
}
const OPPOSITE_GENDER_LINK = "opposite-gender";

interface SaleLandingPageLinkProps {
    item: Item;
    type: string;
}

export function SaleLandingPageLink({ item, type }: SaleLandingPageLinkProps): JSX.Element {
    const isOppositeGenderLink = type === OPPOSITE_GENDER_LINK;
    return (
        <div className={clsx({ [styles.oppositeGenderLink]: isOppositeGenderLink })}>
            <a id={type} href={item.url}>
                <span className={styles.link}>
                    {item.title} <SVGIcon className={styles.chevron} name={"chevron"} />
                </span>
            </a>
        </div>
    );
}

export default SaleLandingPageLink;
