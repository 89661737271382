import React, { useEffect, useState } from "react";
import { PromoBannerProps } from "web/react/components/promo-section/promo-section";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import styles from "./sale-landing-page-promo-banner.module.css";

export function SaleLandingPagePromoBanner({
    title,
    url,
    desktop,
    mobile,
}: PromoBannerProps): JSX.Element {
    const { isDesktopViewport } = useDomViewport();
    const [moderatedImage, setModeratedImage] = useState(desktop);
    useEffect(() => {
        setModeratedImage(isDesktopViewport ? desktop : mobile);
    }, [isDesktopViewport]);

    return (
        <a href={url}>
            <div
                style={{
                    backgroundImage: `url(${moderatedImage})`,
                }}
                className={styles.headerWrap}
            >
                <h1 className={styles.title}>{title}</h1>
            </div>
        </a>
    );
}

export default SaleLandingPagePromoBanner;
