import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { PromoCard, PromoCardPropType } from "web/react/components/promo-card/promo-card";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { gettext } from "web/script/modules/django-i18n";
import styles from "./retailer-promotion-hub.module.css";

function createMarkup(value) {
    return { __html: value };
}

export function RetailerPromotionHub({
    retailerName,
    retailerLogo,
    activeCount,
    lastUpdated,
    activePromotions,
    relatedPromotions,
    inactivePromotions,
    similarRetailers,
    totalInactiveCount,
    totalDiscountCodeCount,
    highestDiscount,
    instructionText,
    policyText,
    tipsText,
    backUrl,
}) {
    const lastUpdatedBox = (
        <span
            className={clsx(styles.lastUpdatedText, styles.lastUpdated)}
            dangerouslySetInnerHTML={createMarkup(
                gettext("promotion_message.designer.last_updated_label", {
                    interval: lastUpdated,
                })
            )}
        ></span>
    );
    const activePromotionsBox = (
        <div className={styles.promotions}>
            <h2 className={styles.sectionTitle}>
                {gettext("promotion_message.designer.latest_promotions_label")}
            </h2>
            <div className={styles.promotionCards}>
                {activePromotions.map((promotion) => {
                    return (
                        <div key={promotion.id} className={styles.promotionCard}>
                            <PromoCard {...promotion} />
                        </div>
                    );
                })}
            </div>
        </div>
    );

    const relatedPromotionsBox = (
        <div className={styles.promotions}>
            <h2 className={styles.sectionTitle}>
                {gettext("promotion_message.designer.related_promotions_label")}
            </h2>
            <div className={styles.promotionCards}>
                {relatedPromotions.map((promotion) => {
                    return (
                        <div key={promotion.id} className={styles.promotionCard}>
                            <PromoCard {...promotion} />
                        </div>
                    );
                })}
            </div>
        </div>
    );

    const inactivePromotionsBox = (
        <div className={styles.promotions}>
            <h2 className={styles.sectionTitle}>
                {gettext("promotion_message.designer.expired_promotions_label")}
            </h2>
            <div className={styles.promotionCards}>
                {inactivePromotions.map((promotion) => {
                    return (
                        <div key={promotion.id} className={styles.promotionCard}>
                            <PromoCard {...promotion} />
                        </div>
                    );
                })}
            </div>
        </div>
    );

    const similarRetailersBox = (
        <div>
            <h2 className={styles.sectionTitle}>
                {gettext("promotion_message.designer.similar_retailers_label")}
            </h2>
            <div className={styles.similarRetailers}>
                {similarRetailers.map((retailer) => {
                    return (
                        <a
                            key={retailer.name}
                            href={retailer.href}
                            className={styles.similarRetailer}
                        >
                            <div className={styles.similarRetailerLogo}>
                                <img src={retailer.logo} />
                            </div>
                            <div className={styles.similarRetailerName}>
                                <span>{retailer.name}</span>
                            </div>
                        </a>
                    );
                })}
            </div>
        </div>
    );

    const retailerImage = <img src={retailerLogo} alt={retailerName} />;

    return (
        <div className={styles.page}>
            <div className={styles.header}>
                <div className={styles.navBox}>
                    <div className={styles.container}>
                        <a href={backUrl} className={styles.nav}>
                            <div className={styles.navIcon}>
                                <SVGIcon name="round-chevron" rotate={180} />
                            </div>
                            <div>
                                {gettext("promotion_message.designer.back_to_browse.link", {
                                    retailer_name: retailerName,
                                })}
                            </div>
                        </a>
                    </div>
                </div>
                <div className={styles.container}>
                    <div className={styles.retailerHeader}>
                        <div className={styles.retailerLogo}>{retailerLogo && retailerImage}</div>
                        <div className={styles.retailerInfo}>
                            <a href={backUrl}>
                                <h1
                                    className={styles.title}
                                    dangerouslySetInnerHTML={createMarkup(
                                        gettext("promotion_message.designer.heading", {
                                            retailer_name: retailerName,
                                        })
                                    )}
                                ></h1>
                            </a>
                            <span
                                className={styles.activeCount}
                                dangerouslySetInnerHTML={createMarkup(
                                    gettext("promotion_message.designer.active_promos_label", {
                                        count: activeCount,
                                    })
                                )}
                            ></span>
                            {lastUpdated && lastUpdatedBox}
                        </div>
                    </div>
                    <div className={styles.lastUpdatedMobile}>{lastUpdated && lastUpdatedBox}</div>
                </div>
            </div>
            <div className={styles.container}>
                {activePromotions.length > 0 && activePromotionsBox}
                {relatedPromotions.length > 0 && relatedPromotionsBox}
                {inactivePromotions.length > 0 && inactivePromotionsBox}
                {similarRetailers.length > 0 && similarRetailersBox}
            </div>
            <div className={styles.promotionStatsBox}>
                <div className={styles.container}>
                    <h2>{gettext("promotion_message.designer.promotions_stats_label")}</h2>
                    <div className={styles.promotionStats}>
                        <div className={styles.promotionStat}>
                            <div
                                dangerouslySetInnerHTML={createMarkup(
                                    gettext(
                                        "promotion_message.designer.promotions_stats.active_retailer_promotions",
                                        {
                                            retailer_name: retailerName,
                                        }
                                    )
                                )}
                            ></div>
                            <div className={styles.promotionStatInfo}>{activeCount}</div>
                        </div>
                        <div className={styles.promotionStat}>
                            <div
                                dangerouslySetInnerHTML={createMarkup(
                                    gettext(
                                        "promotion_message.designer.promotions_stats.expired_retailer_promotions",
                                        {
                                            retailer_name: retailerName,
                                        }
                                    )
                                )}
                            ></div>
                            <div className={styles.promotionStatInfo}>{totalInactiveCount}</div>
                        </div>
                        <div className={styles.promotionStat}>
                            <div
                                dangerouslySetInnerHTML={createMarkup(
                                    gettext(
                                        "promotion_message.designer.promotions_stats.number_retailer_discount_codes",
                                        {
                                            retailer_name: retailerName,
                                        }
                                    )
                                )}
                            ></div>
                            <div className={styles.promotionStatInfo}>{totalDiscountCodeCount}</div>
                        </div>
                        <div className={styles.promotionStat}>
                            <div
                                dangerouslySetInnerHTML={createMarkup(
                                    gettext(
                                        "promotion_message.designer.promotions_stats.highest_discount_retailer",
                                        {
                                            retailer_name: retailerName,
                                        }
                                    )
                                )}
                            ></div>
                            <div className={styles.promotionStatInfo}>{highestDiscount}</div>
                        </div>
                    </div>
                    <div className={styles.promotionStats}>
                        <h2>
                            {gettext("promotion_message.designer.promotion_instructions_title")}
                        </h2>
                        <p dangerouslySetInnerHTML={createMarkup(instructionText)}></p>
                    </div>
                    <div className={styles.promotionStats}>
                        <h2>{gettext("promotion_message.designer.retailer_policies_title")}</h2>
                        <p dangerouslySetInnerHTML={createMarkup(policyText)}></p>
                    </div>
                    <div className={styles.promotionStats}>
                        <h2>
                            {gettext(
                                "promotion_message.designer.generic_promotion_description_title"
                            )}
                        </h2>
                        <p dangerouslySetInnerHTML={createMarkup(tipsText)}></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RetailerPromotionHub;

const similarRetailerPropType = PropTypes.shape({
    name: PropTypes.string,
    logo: PropTypes.string,
});

RetailerPromotionHub.propTypes = {
    retailerName: PropTypes.string,
    retailerLogo: PropTypes.string,
    activeCount: PropTypes.string,
    lastUpdated: PropTypes.string,
    activePromotions: PropTypes.arrayOf(PromoCardPropType),
    relatedPromotions: PropTypes.arrayOf(PromoCardPropType),
    inactivePromotions: PropTypes.arrayOf(PromoCardPropType),
    similarRetailers: PropTypes.arrayOf(similarRetailerPropType),
    totalInactiveCount: PropTypes.string,
    totalDiscountCodeCount: PropTypes.string,
    highestDiscount: PropTypes.string,
    instructionText: PropTypes.string,
    policyText: PropTypes.string,
    tipsText: PropTypes.string,
    backUrl: PropTypes.string,
};
