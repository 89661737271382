import React from "react";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import analytics from "web/script/analytics/analytics";
import { LinkSerializer } from "web/types/serializers";
import styles from "./trends-landing-header.module.css";

interface TrendsLandingHeaderProps {
    pageTitle: string;
    oppositeGenderLink: LinkSerializer;
}

function TrendsLandingHeader({
    pageTitle,
    oppositeGenderLink,
}: TrendsLandingHeaderProps): JSX.Element {
    const onLinkClick = (): void => {
        analytics.event("Trends Hub", "Clicked", "trend_hub_gender_switcher");
    };
    return (
        <div data-testid="trend-page-header" className={styles.headerWrap}>
            <h1 className={styles.title}>{pageTitle}</h1>
            <div className={styles.oppositeGenderLinkWrapper}>
                <a href={oppositeGenderLink.url} onClick={onLinkClick}>
                    <span data-testid="opposite-gender-link" className={styles.link}>
                        {oppositeGenderLink.title}{" "}
                        <SVGIcon className={styles.arrow} name={"arrow-right"} />
                    </span>
                </a>
            </div>
        </div>
    );
}

export default TrendsLandingHeader;
