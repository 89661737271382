import React from "react";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { AboutUsDiscoverMoreSerializer } from "web/types/serializers";
import styles from "./about-us-discover-more.module.css";

function AboutUsDiscoverMore({ items, title }: AboutUsDiscoverMoreSerializer): React.ReactElement {
    return (
        <div className={styles.discoverMore}>
            <h2 dangerouslySetInnerHTML={{ __html: title }} />
            <div className={styles.discoverMoreItemContainer}>
                {items.map(({ content, title, url }) => (
                    <a key={url} href={url} className={styles.discoverMoreItem} title={title}>
                        <div className={styles.discoverMoreItemTitle}>
                            <h3 dangerouslySetInnerHTML={{ __html: title }} />
                            <SVGIcon name={"arrow-right"} />
                        </div>
                        {content && (
                            <div
                                className={styles.discoverMoreItemContent}
                                dangerouslySetInnerHTML={{ __html: content }}
                            />
                        )}
                    </a>
                ))}
            </div>
        </div>
    );
}

export default AboutUsDiscoverMore;
