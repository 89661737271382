import clsx from "clsx";
import React from "react";
import Rail from "web/react/components/LEGACY_rail/rail";
import LazyLoadImage from "web/react/components/lazy-load-image/lazy-load-image";
import styles from "./sale-landing-page-categories-rail.module.css";

interface CategoryItem {
    title: string;
    image_url: string;
    image_alt: string;
    url: string;
}

interface SaleLandingPageCategoryItemProps {
    item: CategoryItem;
}

export function SaleLandingPageCategoryItem({
    item,
}: SaleLandingPageCategoryItemProps): JSX.Element {
    return (
        <a
            data-testid="sale-item"
            className={clsx(styles.saleLandingPageCategoriesCategoryItem)}
            href={item.url}
        >
            <LazyLoadImage
                className={clsx(styles.saleLandingPageCategoriesCategoryImg)}
                src={item.image_url}
                alt={item.image_alt}
            />
            <span className={clsx(styles.saleLandingPageCategoriesCategoryLink)}>
                {item.title.toUpperCase()}
            </span>
        </a>
    );
}

interface SaleLandingPageCategoriesRailProps {
    items: CategoryItem[];
}

export function SaleLandingPageCategoriesRail({
    items,
}: SaleLandingPageCategoriesRailProps): JSX.Element {
    const buttonStyle = clsx(styles.scrollButton);
    const leftButtonStyle = clsx(buttonStyle, styles.left);
    const rightButtonStyle = clsx(buttonStyle, styles.right);

    return (
        <Rail
            className={styles.saleLandingPageCategoriesRail}
            analyticsLabel={"sale_page_category_rail"}
            title={""}
            leftButtonStyle={leftButtonStyle}
            rightButtonStyle={rightButtonStyle}
            buttonIcon={"square-chevron"}
        >
            {items.map((item) => {
                return <SaleLandingPageCategoryItem key={item.url} item={item} />;
            })}
        </Rail>
    );
}

export default SaleLandingPageCategoriesRail;
