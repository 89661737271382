/** @module components/product-card */
import baustein from "baustein";
import SaveForLaterButton from "web/react/components/save-for-later-button/save-for-later-button";
import analytics from "web/script/analytics/analytics";
import "web/script/components/lazy-image";
import "web/script/components/lead-link";
import productClickMixin from "web/script/components/mixins/product-click-mixin";
import SaveForLaterSignupPrompt from "web/script/components/save-for-later-signup-prompt";
import "web/script/components/show-extra";
import "web/script/components/tracked-link";
import environment from "web/script/modules/environment";
import { languageIsEnglish } from "web/script/modules/language";
import userProfiler from "web/script/modules/userprofiler";
import renderReactComponentInDocument from "web/script/utils/render-react";

const IMAGE_LINK_SELECTOR = ".product-card__image-link";
const IMAGE_CLICK_CLASSES = [IMAGE_LINK_SELECTOR];
const DESCRIPTION_CLICK_CLASSES = [".product-card__short-description"];

export const SIGNUP_SELECTOR = ".product-card__save-for-later-signup-prompt";
export const SHORT_DESCRIPTION_SELECTOR = ".product-card__short-description-name";
export const AFFILIATE_SELECTOR = ".product-card__affiliate";

/**
 * The current product card component is only the beginning
 * More functionality incoming.
 * @constructor
 * @alias module:components/product-card
 * @extends module:baustein
 */
export default baustein.register(
    "product-card",
    /** @lends module:components/product-card.prototype */
    {
        mixins: [productClickMixin],

        defaultOptions: {
            newTabEnabled: false,
            overlayEnabled: false,
            productSlug: null,
            productId: null,
            productUid: null,
            productGender: null,
            reason: null,
            inStock: true,
            hasTooltip: false,
            linkId: null,
            showSflTooltip: false,
            productImageUrl: null,
            altImageUrl: null,
            hoverStartTime: null,
            hoverEndTime: null,
            analyticsData: null,
            isIcon: false,
        },

        setupEvents(add) {
            add("click", SIGNUP_SELECTOR, this.onAnonSaveForLater);
            add("click", SHORT_DESCRIPTION_SELECTOR, this.onClickShortDescription);
            add("click", AFFILIATE_SELECTOR, this.onClickAffiliateRetailer);
            add("click", IMAGE_LINK_SELECTOR, this.onClickImage);
        },

        onInsert() {
            if (!languageIsEnglish()) {
                // Don't render save for later button or signup prompt in i18n MVPs
                return;
            }

            this.renderOnlySaveForLater();
        },

        renderOnlySaveForLater() {
            const container = this.el.querySelector(".product-card__details");
            if (!container) {
                return;
            }
            if (userProfiler.isLoggedIn()) {
                if (!this.findComponent("save-for-later-button")) {
                    // Add a react SFL button ...
                    renderReactComponentInDocument(
                        SaveForLaterButton,
                        {
                            type: "icon",
                            isLoggedIn: true,
                            pageSource: "feed",
                            productId: this.options.productId.toString(),
                        },
                        this.el.querySelector(".react__save-for-later")
                    );
                }
            } else if (!this.el.querySelector(".product-card__save-for-later-signup-prompt")) {
                // TODO: Does the React component handle SFL signup prompt + needs a tooltip.
                renderReactComponentInDocument(
                    SaveForLaterButton,
                    {
                        type: "icon",
                        isLoggedIn: false,
                        pageSource: "feed",
                        productId: this.options.productId.toString(),
                        productImageURL: this.options.productImageUrl.toString(),
                    },
                    this.el.querySelector(".react__save-for-later")
                );
            }
        },

        getSaveForLaterSignupPrompt() {
            const enableTooltip = environment.get("s4lFeedTooltip");
            const tooltipAdded = environment.get("s4lFeedTooltipAdded");

            if (enableTooltip && this.options.showSflTooltip && !tooltipAdded) {
                this.hasTooltip = true;
                environment.set({ s4lFeedTooltipAdded: true });
            }
            const prompt = new SaveForLaterSignupPrompt({
                has_tooltip: this.hasTooltip,
                productId: this.options.productId,
                productSlug: this.options.productSlug,
                productGender: this.options.productGender,
            });
            const wrapper = document.createElement("span");
            wrapper.className = "product-card__save-for-later-signup-prompt";
            prompt.appendTo(wrapper);

            return wrapper;
        },

        onAnonSaveForLater() {
            analytics.event("save_for_later", "add_item", "product_card");
            this.emit("productCardInteraction");
        },

        getClickOverlayClasses() {
            return IMAGE_CLICK_CLASSES.concat(DESCRIPTION_CLICK_CLASSES);
        },

        getClickRedirectClasses() {
            return [];
        },

        enable() {
            this.el.classList.remove("product-card--disabled");
        },

        disable() {
            this.el.classList.add("product-card--disabled");
        },

        onClickShortDescription() {
            analytics.event("product_card", "short description");
            this.emit("productCardInteraction");
        },

        onClickAffiliateRetailer() {
            analytics.event("product_card", "retailer");
            this.emit("productCardInteraction");
        },

        onClickImage() {
            analytics.event("product_card", "image");
            this.emit("productCardInteraction");
        },
    }
);
