import clsx from "clsx";
import React from "react";
import Rail from "web/react/components/LEGACY_rail/rail";
import ProductCard from "web/react/components/product-card/react";
import { gettext } from "web/script/modules/django-i18n";
import { NewArrivalsSerializer } from "web/types/serializers";
import styles from "./trends-landing-page-new-arrivals-rail.module.css";

function NewArrivalsRail({ products }: NewArrivalsSerializer): JSX.Element {
    const leftBtnStyle = clsx(styles.scrollButton, styles.left);
    const rightBtnStyle = clsx(styles.scrollButton, styles.right);

    return (
        <div data-testid="new-arrivals-rail" className={styles.railWrapper}>
            <h3 className={styles.titleBlock}>
                {gettext("product.related_products.new_arrivals")}
            </h3>
            <Rail
                analyticsLabel={"best_selling"}
                className={styles.railWrapper}
                leftButtonStyle={leftBtnStyle}
                rightButtonStyle={rightBtnStyle}
                buttonIcon={"arrow-right"}
            >
                {products.map((product) => (
                    <ProductCard key={product.id} product={product} className={styles.cardWrapper}>
                        <ProductCard.Image width={125} height={156}>
                            <ProductCard.Badges />
                        </ProductCard.Image>

                        <ProductCard.SaveForLaterButton isAffiliate={product.is_affiliate} />

                        <ProductCard.Details />
                    </ProductCard>
                ))}
            </Rail>
        </div>
    );
}

export default NewArrivalsRail;
